import React, {useEffect, useState} from 'react';
import Navbar from '../component/navbar';
import Footer from '../component/footer';

import b1 from '../images/clients/Room-boy.webp';
import b2 from '../images/clients/Room-boy2.webp';
import b3 from '../images/clients/Massage-Therapist.webp';
import Marquee from "react-fast-marquee";
import image1 from "../images/homepage/logo-slider/1.webp";
import image2 from "../images/homepage/logo-slider/2.webp";
import image3 from "../images/homepage/logo-slider/3.webp";
import image4 from "../images/homepage/logo-slider/4.webp";
import image5 from "../images/homepage/logo-slider/5.webp";
import image6 from "../images/homepage/logo-slider/6.webp";
import image7 from "../images/homepage/logo-slider/7.webp";
import image8 from "../images/homepage/logo-slider/8.webp";
import image9 from "../images/homepage/logo-slider/9.webp";
import image10 from "../images/homepage/logo-slider/10.webp";
import image11 from "../images/homepage/logo-slider/11.webp";
import image12 from "../images/homepage/logo-slider/12.webp";
import image13 from "../images/homepage/logo-slider/13.webp";
import image14 from "../images/homepage/logo-slider/14.webp";
import image15 from "../images/homepage/logo-slider/15.webp";
import image16 from "../images/homepage/logo-slider/16.webp";
import image17 from "../images/homepage/logo-slider/17.webp";
import image18 from "../images/homepage/logo-slider/18.webp";
import image19 from "../images/homepage/logo-slider/19.webp";
import image20 from "../images/homepage/logo-slider/20.webp";
import image21 from "../images/homepage/logo-slider/21.webp";
import image22 from "../images/homepage/logo-slider/22.webp";
import image23 from "../images/homepage/logo-slider/23.webp";
import image24 from "../images/homepage/logo-slider/24.webp";
import image25 from "../images/homepage/logo-slider/25.webp";
import image26 from "../images/homepage/logo-slider/26.webp";
import image27 from "../images/homepage/logo-slider/27.webp";
import image28 from "../images/homepage/logo-slider/28.webp";
import image29 from "../images/homepage/logo-slider/29.webp";
import image30 from "../images/homepage/logo-slider/30.webp";
import image31 from "../images/homepage/logo-slider/31.webp";
import image32 from "../images/homepage/logo-slider/32.webp";
import image33 from "../images/homepage/logo-slider/33.webp";
import image34 from "../images/homepage/logo-slider/34.webp";
import image35 from "../images/homepage/logo-slider/35.webp";
import axios from "axios";
import BackendUrl from "../Backend-url";
import {useParams} from "react-router-dom";

const PartnerDetails = () => {
    const [loading, setLoading] = useState(true);
    const [partnerDetails, setPartnerDetails] = useState([]);

    const { id } = useParams();


    useEffect(() => {
        const fetchPartnerDetails = async () => {
            try {
                const response = await axios.get(`${BackendUrl}/partners/${id}`);
                console.log(response)
                setPartnerDetails(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchPartnerDetails();
    }, [id]);


    return (
        <div>
            <Navbar />
            <div className="container mx-auto max-w-7xl my-10">

                <div className="relative sm:text-center mb-12">
                    <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                        Our Clients
                    </h3>

                </div>
                <div className="sub_2">
                    <div className="poster_text flex flex-col md:flex-row md:justify-between">
                        <div className="sldful1 mb-8 md:mr-20 md:mb-0 md:w-1/2">

                            <div className="content-slider lg:w-3/3 mx-3  ">
                                <Marquee>
                                    <img className="me-2 logo-images" src={image1} alt="logo 1"/>
                                    <img className="me-2 logo-images" src={image2} alt="logo 2"/>
                                    <img className="me-2 logo-images" src={image3} alt="logo 3"/>
                                    <img className="me-2 logo-images" src={image4} alt="logo 4"/>
                                    <img className="me-2 logo-images" src={image5} alt="logo 5"/>
                                    <img className="me-2 logo-images" src={image6} alt="logo 6"/>
                                    <img className="me-2 logo-images" src={image7} alt="logo 7"/>
                                    <img className="me-2 logo-images" src={image8} alt="logo 8"/>
                                    <img className="me-2 logo-images" src={image9} alt="logo 9"/>
                                    <img className="me-2 logo-images" src={image10} alt="logo 10"/>
                                    <img className="me-2 logo-images" src={image11} alt="logo 11"/>
                                    <img className="me-2 logo-images" src={image12} alt="logo 12"/>



                                </Marquee>

                            </div>

                            <div className="hrader_poster">
                                <h1 className="text-2xl font-bold mb-2">{partnerDetails.title}</h1>
                                <hr className="border-t border-gray-500 my-2" />
                                <p className="text-sm text-gray-500 text-justify">
                                    {partnerDetails.description}
                                </p>
                            </div>
                        </div>
                        <div className="poster_img md:w-1/2">
                            <div className="poster_img_block">
                                <div className="poster_img_block_row flex">
                                    {partnerDetails.poster1 && (
                                        <div className="p_imagers flex-1 m-2">
                                            <img id="myImg" src={`${BackendUrl}/${partnerDetails.poster1}`} alt="Image 1" style={{ width: '250px', height: '220px' }} />
                                        </div>
                                    )}
                                    {partnerDetails.poster2 && (
                                        <div className="p_imagers flex-1 m-2">
                                            <img id="myImg2" src={`${BackendUrl}/${partnerDetails.poster2}`} alt="Image 2" style={{ width: '250px', height: '220px' }} />
                                        </div>
                                    )}
                                </div>
                                <div className="poster_img_block_row flex">
                                    {partnerDetails.poster3 && (
                                        <div className="p_imagers flex-1 m-2">
                                            <img id="myImg3" src={`${BackendUrl}/${partnerDetails.poster3}`} alt="Image 3" style={{ width: '250px', height: '220px' }} />
                                        </div>
                                    )}
                                    {partnerDetails.poster4 && (
                                        <div className="p_imagers flex-1 m-2">
                                            <img id="myImg4" src={`${BackendUrl}/${partnerDetails.poster4}`} alt="Image 4" style={{ width: '250px', height: '220px' }} />
                                        </div>
                                    )}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PartnerDetails;
