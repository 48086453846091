import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import Breadcrumb from '../component/breadcrumbs/breadcrumb';
import { FaSearch, FaFilter  } from "react-icons/fa";
import BackendUrl from "../Backend-url";

import bannerImage from "../images/job-listing/job-listing-wallpaper.webp";
import { HomeIcon } from "@heroicons/react/24/solid";
import '../css/components/loader/loader.css';

import {useNavigate} from "react-router-dom";

const Blog = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({ categories: [], blogs: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        Promise.all([
            axios.get(`${BackendUrl}/blog/categories`),
            axios.get(`${BackendUrl}/blog`)
        ])
            .then(([categoriesResponse, blogsResponse]) => {
                setData({
                    categories: categoriesResponse.data,
                    blogs: blogsResponse.data
                });
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, []);

    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '30vh',
        position: 'relative',
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'BLOG', url: '/blog' },
    ];

    const truncateWords = (text, maxWords) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ') + (words.length > maxWords ? '...' : ''); // Add ellipsis if truncated
    };

    const truncateCharacters = (text, maxCharacters) => {
        if (text.length <= maxCharacters) {
            return text;
        }

        const truncatedText = text.slice(0, maxCharacters);
        return truncatedText + '...';
    };

    const handleCardClick = (blogId) => {
        navigate(`/blog/details/${blogId}`); // Navigate to next component with image ID as parameter
    };

    const [selectedFilter, setSelectedFilter] = useState(undefined);

    const handleFilterButtonClick = (filterType) => {
        setSelectedFilter(filterType)
    }

    return (
        <div className="bg-stone-200">
            <Navbar />
            <section>
                <div className="flex items-center justify-center" style={containerStyle}>
                    <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center">
                        <h2 className="text-slate-100 text-4xl font-bold uppercase">Tee Gee Blog</h2>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container mx-auto max-w-7xl">
                    <div className="mt-2">
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </section>
            <section>

                <div className="container bg-gray-100 mx-auto px-2 sm:px-5 py-2 max-w-7xl flex flex-col sm:flex-row">
                    {/* Categories Section */}
                    <div className="w-full sm:w-1/4 lg:w-3/12 p-1 mb-4 sm:mb-0 h-full rounded-[36px]">
                        <div className="flex flex-col p-2 h-full rounded-[36px]">

                            <div className="md:hidden">
                                <div className="sm:block">
                                    <div className="sm:block p-2 h-2/5 rounded-[36px]">
                                        <div className="text-md-end font-bold px-1 py-2"></div>
                                        <div className="flex justify-center items-center">
                                            <input type="text" placeholder="Search Here"
                                                   className="w-full sm:w-96 border py-2.5 px-4 mr-2 rounded-[36px]"/>
                                            <button className="bg-blue-500 text-white px-3 py-3 rounded-[36px]"><FaSearch/>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="">
                                <div className="">
                                    <div className="hidden md:block">
                                        <div className="rounded-[36px] bg-white px-3 py-6">
                                            <div
                                                className={`uppercase font-bold flex items-center text-blue-500 justify-center text-center py-1 px-2 mt-1 flex-row`}>
                                                <FaFilter className="text-xs text-blue-500"/>
                                                <h2>Filter By</h2>
                                            </div>
                                            {data.categories.length === 0 ? (
                                                <div className="loader">
                                                </div>
                                            ) : (
                                                data.categories
                                                    .map(category => (
                                                        <div
                                                            className={`hover:bg-blue-500 border-2 hover:text-white flex items-center justify-center text-center py-1 border-blue-500 rounded-full px-2 mt-1 flex flex-row`}
                                                            key={category.category_id}
                                                            onClick={() => handleFilterButtonClick(category.category_id)}>>
                                                            <div className={`text-center ${selectedFilter === category.category_id ? 'focus:bg-blue-500': 'hover:text-white hover:bg-blue-500'}`}>{category.category_name}</div>
                                                        </div>
                                                    ))
                                            )}
                                        </div>
                                    </div>
                                </div>


                            </div>


                            <div className="flex md:block justify-evenly flex-row px-0 py-2">
                                {data.categories.length === 0 ? (
                                    <div className="loader"></div>
                                ) : (
                                    data.categories
                                        .map(category => (
                                            <div
                                                className={`sm:text-xs md:hidden hover:bg-blue-500 border-2 hover:text-white py-1 border-blue-500 rounded-full px-2 mt-1 flex flex-row`}
                                                key={category.category_id}
                                                onClick={() => handleFilterButtonClick(category.category_id)}>
                                                <div className={`text-center ${selectedFilter === category.category_id ? 'focus:bg-blue-500': 'hover:text-white hover:bg-blue-500'}`}
                                                   >{category.category_name}</div>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </div>

                    {/* Blogs Section */}
                    <div className="w-full sm:w-1/2 p-2 h-full rounded-[36px]">
                        <div className="text-sm text-blue-500 font-bold">Today History</div>
                        {data.blogs.length === 0 ? (
                            <div className="loader"></div>
                        ) : (
                            data.blogs
                                .sort((a, b) => new Date(b.date_published) - new Date(a.date_published))
                                .map((blog, index) => {

                                    if (blog.subcategory_id === 3 && selectedFilter === 3) {
                                        return null; // Return null to explicitly exclude the element
                                    }
                                    else if (
                                        blog.subcategory_id === 3 && selectedFilter === 5
                                        || selectedFilter === blog.subcategory_id){
                                        return (
                                            <div
                                                className="bg-white lg:max-w-3xl shadow-md p-2 h-1/2 my-2 rounded-[36px] cursor-auto"
                                                key={blog.blog_id}
                                                onClick={() => handleCardClick(blog.blog_id)}>
                                                <div className="md:flex md:flex-col">
                                                    <div className="md:shrink-0">
                                                        <img
                                                            className="h-48 rounded-[36px] w-full object-cover md:w-full"
                                                            src={`${BackendUrl}/${blog.image}`}
                                                            alt={blog.title}/>
                                                    </div>
                                                    <div className="p-8">
                                                        <a href="#"
                                                           className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">{blog.title}</a>
                                                        <p className="mt-2 text-slate-500">{truncateCharacters(blog.content, 120)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }if(selectedFilter === undefined || selectedFilter ===""){
                                        return (
                                            <div
                                                className="bg-white lg:max-w-3xl shadow-md p-2 h-1/2 my-2 rounded-[36px] cursor-auto"
                                                key={blog.blog_id}
                                                onClick={() => handleCardClick(blog.blog_id)}>
                                                <div className="md:flex md:flex-col">
                                                    <div className="md:shrink-0">
                                                        <img
                                                            className="h-48 rounded-[36px] w-full object-cover md:w-full"
                                                            src={`${BackendUrl}/${blog.image}`}
                                                            alt={blog.title}/>
                                                    </div>
                                                    <div className="p-8">
                                                        <a href="#"
                                                           className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">{blog.title}</a>
                                                        <p className="mt-2 text-slate-500">{truncateCharacters(blog.content, 120)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        );

                                    }



                                })
                        )}
                    </div>

                    {/* Search and Recent News Section */}
                    <div className="w-full sm:w-1/4 lg:w-3/12 p-1 mb-4 sm:mb-0 h-full rounded-[36px]">
                        <div className="p-2 h-full rounded-[36px]">

                            <div className="hidden md:block p-2 h-2/5 rounded-[36px]">
                                <div className="text-sm mb-2 px-1 py-2">Search</div>
                                <div className="flex items-center">
                                    <input
                                        type="text"
                                        placeholder="Search Here"
                                        className="border p-2 mr-2 w-full rounded-[36px]"
                                    />
                                    <button className="bg-blue-500 text-white px-3 py-3 rounded-[50px]"><FaSearch/>
                                    </button>
                                </div>
                            </div>

                            <div className="p-2 h-3/5 mt-3 rounded-[36px]">
                                <div className="text-sm text-blue-500 font-bold mb-2 px-2 py-2">Most Viewed</div>
                                {data.blogs.length === 0 ? (
                                    <div className="loader"></div>
                                ) : (
                                    data.blogs
                                        .sort((a, b) => b.view_count - a.view_count)
                                        .slice(0, 5)
                                        .map(blog => (
                                            <div
                                                className="lg:max-w-xl mx-auto bg-white flex rounded-[36px] shadow-md overflow-hidden"
                                                key={blog.blog_id}
                                                onClick={() => handleCardClick(blog.blog_id)}>
                                                <div className="md:flex items-center justify-center flex-col">
                                                    <div className="flex items-center px-2 sm:px-0 justify-center md:shrink-0">
                                                        <img
                                                            className="h-48 mt-1  items-center w-full object-cover  md:h-32 rounded-[36px] w-screen"
                                                            src={`${BackendUrl}/${blog.image}`}
                                                            alt={blog.title}/>
                                                    </div>
                                                    <div className="p-8">
                                                        <a href="#"
                                                           className="block mt-1 text-lg leading-tight font-medium text-black hover:underline">{blog.title}</a>
                                                        <p className="mt-2 text-slate-500">{truncateCharacters(blog.content, 30)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <Footer/>
        </div>
    );
};

export default Blog;
