import React, { useRef } from 'react';
import logo from '../images/logo/logo.webp'; // Import your logo image
import logo1 from '../images//job-listing/12345.jpg'; // Import your logo image
import html2pdf from 'html2pdf.js';

const Pdf = () => {
    const pdfRef = useRef();

    const saveAsPdf = () => {
        const input = pdfRef.current;
        html2pdf()
            .from(input)
            .set({
                margin: 0,
                filename: 'your_pdf_filename.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'cm', format: 'a4', orientation: 'portrait' }
            })
            .save();
    };

    return (
        <div ref={pdfRef} className="p-4 bg-white shadow-md w-8/12 mx-auto mt-0 border-4 border-orange-700" style={{ height: "29.7cm", width: "21cm", backgroundColor: "#f3f4f6" }}>
            {/* Logo and Company Name */}
            <div className="flex justify-between items-center ">
                <div className="w-2/12 bg-blue-400">
                    <img src={logo} alt="Company Logo" className="h-auto max-h-20" /> {/* Adjust the maximum height as needed */}
                </div>
                <div className="w-10/12 text-center bg-emerald-300">
                    <p className="text-2xl font-bold mb-1">TEE-GEE AGENCY(PVT) LTD</p>
                    <h6 className="text-1xl font-bold mb-1">MANPOWER, TRAVEL AGENT AND ALLIED SERVICES</h6>
                    <h2 className="text-lg">اسم شركتك</h2> {/* Arabic Company Name */}
                </div>
            </div>

            <h2 className="text-2xl font-bold text-center bg-gray-500">Application for Employment</h2>
            <h3 className="text-2xl font-bold text-center bg-gray-500">اسم شركتك</h3> {/* Arabic Company Name */}
            {/* Content goes here */}


            <div className="flex justify-between items-center mb-2">
                <div className="w-3/12 ">
                    <img
                        src={logo1}
                        alt="Company Logo"
                        className=" border border-gray-800 p-1"
                        width="200"
                        height="200"
                    />
                </div>



                <div className="w-9/12">
                    <table className="w-full mt-3 ">
                        <tr>
                            <td className="border-1  border-orange-700">REF.NO:</td>
                            <td className="border-1  border-orange-700">1</td>
                            <td className="border-1  border-orange-700">2</td>
                        </tr>
                        <tr>
                            <td className="border-1  border-orange-700">Post Applied For</td>
                            <td className="border-1  border-orange-700">1</td>
                            <td className="border-1  border-orange-700">2</td>
                        </tr>
                        <tr>
                            <td className="border-1  border-orange-700">Monthly Salary</td>
                            <td className="border-1  border-orange-700">1</td>
                            <td className="border-1  border-orange-700">2</td>
                        </tr>
                        <tr>
                            <td className="border-1  border-orange-700">Contract Period</td>
                            <td className="border-1  border-orange-700">1</td>
                            <td className="border-1  border-orange-700">2</td>
                        </tr>

                        <tr>
                            <td className="border-1  border-orange-700">Name IN Full</td>
                            <td className="border-1  border-orange-700">1</td>
                            <td className="border-1  border-orange-700">2</td>
                        </tr>
                    </table>

                </div>


            </div>



            <div className="flex justify-between items-center mb-2">
                <div className="w-1/2 ">
                    <div className="">
                        <h2 className="bg-red-500"> DETAILS OF APPLICANT</h2>
                        <table className="w-full  text-[15px]">
                            <tr>
                                <td className="border-1  border-orange-700">NATIONALITY</td>
                                <td className="border-1  border-orange-700 w-4/12">1</td>
                                <td className="border-1  border-orange-700 w-3/12">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">RELIGION</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">DATE OF BIRTH</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">PLACE OF BIRTH</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">LIVING TOWN</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">MARITAL STATS</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">NO. OF CHILDREN</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">WEIGHT</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">HEIGHT</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">COMPLEXION</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">AGE</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">2</td>
                            </tr>
                        </table>
                    </div>
                    <div className=" mt-2">
                        <h2 className="bg-red-500">KNOWLEDGE OF LANGUAGES</h2>
                        <table className="w-full ">
                            <tr>
                                <td className="border-1  border-orange-700" colspan="2">English</td>
                                <td className="border-1  border-orange-700"  colspan="2">Arabic</td>


                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">EXCELLENT</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">GOOD</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">FAIR</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">POOR</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                                <td className="border-1  border-orange-700">Column</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700" colspan="4">EDUCATIONAL QUALIFICATION</td>


                            </tr>


                        </table>
                    </div>
                    <div className="mt-2">
                        <h2 className="bg-red-500"> PREVIOUS EMPLOYMENT ABROAD</h2>
                        <table className="w-full ">
                            <tr>
                                <td className="border-1  border-orange-700">REF.NO:</td>
                                <td className="border-1  border-orange-700">Column 2 Row 1</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">Post Applied For</td>
                                <td className="border-1  border-orange-700">Column 2 Row 2</td>

                            </tr>



                        </table>
                    </div>
                    <div className="mt-2">
                        <h2 className="bg-red-500"> WORK EXPERIENCE</h2>
                        <table className="w-full ">
                            <tr>
                                <td className="border-1  border-orange-700">DRIVING</td>
                                <td className="border-1  border-orange-700  w-1/12"> </td>
                                <td className="border-1  border-orange-700  w-2/12"></td>
                                <td className="border-1  border-orange-700">WASHING</td>
                                <td className="border-1  border-orange-700   w-1/12"> </td>
                                <td className="border-1  border-orange-700  w-2/12"></td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">SEWING</td>
                                <td className="border-1  border-orange-700   w-1/12"> </td>
                                <td className="border-1  border-orange-700  w-2/12"></td>
                                <td className="border-1  border-orange-700">CLEANING</td>
                                <td className="border-1  border-orange-700   w-1/12"> </td>
                                <td className="border-1  border-orange-700  w-2/12"></td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">COOKING</td>
                                <td className="border-1  border-orange-700  w-1/12"> </td>
                                <td className="border-1  border-orange-700  w-2/12"></td>
                                <td className="border-1  border-orange-700">BABY SITTING</td>
                                <td className="border-1  border-orange-700   w-1/12"> </td>
                                <td className="border-1  border-orange-700  w-2/12"></td>
                            </tr>

                        </table>
                    </div>
                </div>


                <div className="w-1/2 ">
                    <div className=" mb-2 pl-1">
                        <h2  className="bg-red-500"> Passport details</h2>
                        <table className="w-full  ">
                            <tr>
                                <td className="border-1  border-orange-700">REF.NO:</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">1</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">Post Applied For</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">1</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">Monthly Salary</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">1</td>
                            </tr>
                            <tr>
                                <td className="border-1  border-orange-700">Contract Period</td>
                                <td className="border-1  border-orange-700">1</td>
                                <td className="border-1  border-orange-700">1</td>
                            </tr>


                        </table>
                    </div>
                    <div className="">
                        <img src={logo1} alt="Company Logo" className=" p-1" width="auto" height="400" />
                    </div>
                </div>

            </div>


            <h5 className=" text-xs text-center">I HEREBY DECLARE THAT ABOVE PARTICULARS FURNISHED BY ME ARE TRUE AND ACCURATE TO THE BEST OF MY KNOWLEDGE.</h5>

            <button onClick={saveAsPdf}>Save as PDF</button>


        </div>
    );
};

export default Pdf;
