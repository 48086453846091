import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Homepage from "./view/homepage";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/tailwind.css';

import About_us from "./view/about-us";
import OurServices from "./view/services";
import ContactUs from "./view/contact-us";
import Workplace from "./view/workplace";
import Blog from "./view/blog";
import JobListing from "./view/job-listings";
import Partners from "./view/partners";
import Project from "./view/projects";
import Hospitality_Industry from "./view/partner-details";
import JobDetails from "./view/job-details";
import BlogDetails from "./view/blog-details";
import Pdf from "./component/pdf";

import './css/Font/font.css';
import MaintenancePage from "./view/maintenance-page";
import PartnerDetails from "./view/partner-details";
import ProjectDetails from "./view/project-details";
import Applicants_sample from "./view/applicants_sample";
import Applicants from "./view/applicants_sample";
import SocialMediaBar from "./component/social_media_bar";
import React from "react";
import Bottomto_up from "./component/bottomto_up";



function App() {
  return (
      <>
        <Bottomto_up/>
        <SocialMediaBar/>
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
            <Route exact path="/about-us" element={<About_us />} />
            <Route exact path="/our-services" element={<OurServices />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/workplace" element={<Workplace />} />

            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/details/:id" element={<BlogDetails />} />

            <Route exact path="/job-listing" element={<JobListing />} />
            <Route path="/job-listing/details/:id" element={<JobDetails />} />

            <Route exact path="/partners" element={<Partners />} />
            <Route exact path="/partners/:id" element={<PartnerDetails/>} />

            <Route exact path="/projects" element={<Project/>} />
            <Route exact path="/project/details/:id" element={<ProjectDetails/>} />

            <Route exact path="/applicants" element={<Applicants/>} />


            <Route exact path="/pdf" element={<Pdf />} />



        </Routes>
      </Router>
      </>
  );
}

export default App;
