import React, {useEffect} from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../../css/Font/font.css';

const About = () => {
    // useEffect(() => {
    //     AOS.init({ duration: 1000 }); // Initialize AOS with desired options
    // }, []);

    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);

    return (
        <div>


            <section className="relative py-28 bg-gray-900 font-poppins font-200">
                <div className="relative z-10 max-w-screen-xl mx-auto px-4 md:px-8">
                    <div className="max-w-7xl mx-auto text-center" data-aos="zoom-in">

                        <h3 className="text-3xl font-semibold sm:text-4xl text-blue-100  pb-6" data-aos="zoom-in">About Us</h3>
                        <figure>
                            <blockquote>
                                <p className="text-white text-xl font-semibold sm:text-2xl " data-aos="fade-up">
                                    “TeeGee Agency (PVT) Ltd has been spreading our reputation for 38 years since 1989 as a reputed recruitment consultancy company in Sri Lanka.
                                    For 38 years in our journey so far, we have helped our clients to fulfill their lives with meaningful employment and development opportunities.
                                    In our journey so far, we have hired thousands of people for a number of industries such as construction, shipping, automobiles, hotel industry, transportation, hospitality service and domestic service.
                                    TeeGee Agency (PVT) Ltd is listed as an agency that provides services to a large number of countries around the world.
                                    We are committed to finding the best choice for all of the people we interact with.
                                    It is our primary aim to provide more than what we expect from all personnel by being creative,
                                    efficient and dedicated to them and building effective relationships with them“
                                </p>
                            </blockquote>
                            {/*<div className="mt-6">*/}
                            {/*    <img src="https://api.uifaces.co/our-content/donated/xZ4wg2Xj.jpg" className="w-16 h-16 mx-auto rounded-full" />*/}
                            {/*    <div className="mt-3">*/}
                            {/*        <span className="block text-white font-semibold">Martin escobar</span>*/}
                            {/*        <span className="block text-blue-100 text-sm mt-0.5">Founder of meta</span>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <a href="about_us" className=" font-poppins font-600 my-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-5 rounded-full inline-block" data-aos="zoom-in">
                                More
                            </a>

                        </figure>
                    </div>
                </div>
                <div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
                {/*<div className="absolute top-0 w-full h-full" style={{ background: "linear-gradient(268.24deg, rgba(59, 130, 246, 0.76) 50%, rgba(59, 130, 246, 0.545528) 80.61%, rgba(55, 48, 163, 0) 117.35%)" }}></div>*/}
            </section>
        </div>
    );
};

export default About;