import React from 'react';
import { FaFile, FaFacebookF, FaWhatsapp, FaLinkedinIn, FaTiktok } from 'react-icons/fa';
import '../css/homepage/SocialMediaLinks.css';
import {Link} from "react-router-dom";
const SocialMediaLinks = () => {

    const handleDownload = () => {
        // Create an anchor element
        const link = document.createElement('a');
        link.href = '/Company-Profile.pdf';
        // link.target = '_blank';
        link.download = 'Company_Profile.pdf'; // This will set the file name when downloading
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <div className="sticky-top">
            <ul className="float">
                <li><a href="/" target="_blank" onClick={handleDownload}>
                    <FaFile />
                </a>
                </li>
                <li><a href="https://www.facebook.com/profile.php?id=100083030334260&mibextid=LQQJ4d"><FaFacebookF /></a></li>
                <li><a href="https://wa.me/94764368860"><FaWhatsapp /></a></li>
                <li><a href="http://www.linkedin.com/in/tee-gee-agency-2882a0225"><FaLinkedinIn /></a></li>
                <li><a href="https://www.tiktok.com/@teegeeagency?_t=8ZfoAKR7B9m&_r=1"><FaTiktok /></a></li>
            </ul>
        </div>
    );
};

export default SocialMediaLinks;
