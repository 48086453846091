import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import Breadcrumb from '../component/breadcrumbs/breadcrumb';

import BackendUrl from "../Backend-url";
import bannerImage from '../images/job-listing/job-listing-wallpaper.webp';
import sample from '../images/job-listing/12345.jpg';
import { HomeIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";

const JobListing = () => {
    const navigate = useNavigate();
    const [vacancies, setVacancies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState({
        title: '',
        country: '',
        employmentType: ''
    });


    const vacanciesPerPage = 6;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BackendUrl}/vacancy`);
                const sortedVacancies = response.data.sort((a, b) => new Date(b.added_date) - new Date(a.added_date));
                setVacancies(sortedVacancies);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '35vh',
        position: 'relative',
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'VACANCIES', url: '/job_Listing' },
    ];

    const handleCardButtonClick = (imageId) => {
        navigate(`/job-listing/details/${imageId}`);
    };

    const handleSearchInputChange = (e) => {
        const { name, value } = e.target;
        setSearchTerm(prevSearchTerm => ({
            ...prevSearchTerm,
            [name]: value
        }));
    };

    const filteredVacancies = vacancies.filter((vacancy) =>
        vacancy.vacancy_name.toLowerCase().includes(searchTerm.title.toLowerCase()) &&
        (searchTerm.country === '' || vacancy.country.toLowerCase() === searchTerm.country.toLowerCase()) &&
        (searchTerm.employmentType === '' || vacancy.employment_type.toLowerCase() === searchTerm.employmentType.toLowerCase())
    );

    const availableCountries = [...new Set(vacancies.map(vacancy => vacancy.country))];
    const availableEmploymentTypes = [...new Set(vacancies.map(vacancy => vacancy.employment_type))];


    const renderCountryOptions = () => {
        return availableCountries.map(country => (
            <option key={country} value={country}>{country}</option>
        ));
    };

    // Render dropdown options for employment type
    const renderEmploymentTypeOptions = () => {
        return availableEmploymentTypes.map(type => (
            <option key={type} value={type}>{type}</option>
        ));
    };

    const truncateCharacters = (text, maxCharacters) => {
        if (text.length <= maxCharacters) {
            return text;
        }

        const truncatedText = text.slice(0, maxCharacters);
        return truncatedText + '...';
    };

    const renderVacancies = () => {
        const indexOfLastVacancy = currentPage * vacanciesPerPage;
        const indexOfFirstVacancy = indexOfLastVacancy - vacanciesPerPage;
        const currentVacancies = filteredVacancies.slice(indexOfFirstVacancy, indexOfLastVacancy);

        return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {currentVacancies.map((vacancy) => (
                    <div key={vacancy.id} className="main-card bg-stone-100 rounded-[36px] overflow-clip border-none">
                        <div className="card-header p-0 border-none">
                            <img src={`${BackendUrl}/${vacancy.imagePath}`} alt="job poster"/>
                        </div>
                        <div className="card-content px-6 border-none">
                            <h6 className="pt-4 text-xl font-medium">{vacancy.vacancy_name}</h6>
                            <p className="pt-2 text-sm font-thin" dangerouslySetInnerHTML={{ __html: truncateCharacters(vacancy.description, 50) }}></p>

                        </div>
                        <div className="pt-4 card-footer flex px-6 justify-content-between bg-stone-100 border-none pb-3">
                            <div className="price block">
                                <p>{vacancy.employment_type}</p>
                                <p className="font-bold">{vacancy.price}</p>
                            </div>
                            <div>
                                <button
                                    className="bg-blue-900 hover:bg-blue-700 text-stone-100 font-bold py-2 px-4 rounded-full uppercase"
                                    onClick={() => handleCardButtonClick(vacancy.id)}>
                                    Apply Now
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <div className="bg-stone-200">
            <Navbar/>
            <section>
                <div style={containerStyle}>
                    <div className="py-12 max-w-7xl mx-auto">
                        <h2 className="text-slate-100 text-4xl font-bold">Looking for a career opportunity?</h2>
                        <p className="text-slate-300 text-1xl font-thin mt-3">Are you prepared to embark on a journey to explore fresh avenues in your professional
                            <br/>journey? Uncover thrilling opportunities for a career change with Tee Gee. Allow us to<br/>
                            lead you towards a brighter and more satisfying future.</p>
                    </div>
                </div>
            </section>
            <section className="max-w-7xl mx-auto py-2">
                <Breadcrumb items={breadcrumbItems} />
            </section>
            <section className="bg-stone-50 rounded-[35px] px-8 pb-4 w-11/12 h-auto sm:w-3/4 sm:top-[450px] mx-auto max-w-7xl mt-3">
                <div className="text-center">
                    <h1 className="py-3 px-4 text-2xl font-medium">Search the job</h1>
                </div>
                <div className="flex flex-col sm:flex-row px-2">
                    <input
                        type="text"
                        name="title"
                        id="title"
                        autoComplete="title"
                        value={searchTerm.title}
                        onChange={handleSearchInputChange}
                        className="block mt-3 w-full sm:w-1/3 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                        placeholder="Find the job title, company, keyword"
                    />
                    <select
                        name="country"
                        id="country"
                        autoComplete="country"
                        className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                        onChange={handleSearchInputChange}
                        value={searchTerm.country}
                    >
                        <option value="">Select Country</option>
                        {renderCountryOptions()}
                    </select>
                    <select
                        name="employmentType"
                        id="employmentType"
                        autoComplete="employmentType"
                        className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                        onChange={handleSearchInputChange}
                        value={searchTerm.employmentType}
                    >
                        <option value="">Select Employment Type</option>
                        {renderEmploymentTypeOptions()}
                    </select>
                    <button
                        className="mt-3 sm:mt-0 ml-0 sm:ml-3 bg-blue-900 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded disabled">
                        SEARCH JOB
                    </button>
                </div>
            </section>
            <section className="mt-12 mx-auto px-4 max-w-7xl md:px-8">
                <div className="text-center">
                    <h1 className="text-2xl text-gray-800 font-black sm:text-3xl sm:pt-1">
                        Latest Vacancies
                    </h1>
                </div>
                <div className="vacancies py-4">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            {renderVacancies()}
                            <div className="flex justify-center mt-4">
                                <ul className="pagination">
                                    {[...Array(Math.ceil(filteredVacancies.length / vacanciesPerPage)).keys()].map((number) => (
                                        <li key={number} className="page-item">
                                            <button onClick={() => paginate(number + 1)} className="page-link">
                                                {number + 1}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </>
                    )}
                </div>
            </section>
            <Footer/>
        </div>
    );
};

export default JobListing;
