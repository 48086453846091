import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Navbar from '../component/navbar';
import Footer from '../component/footer';
import * as PropTypes from 'prop-types';
import bannerImage from "../images/job-listing/job-listing-wallpaper.webp";
import axios from "axios";
import BackendUrl from "../Backend-url";


function CloseIcon(props) {
    return null;
}

CloseIcon.propTypes = { className: PropTypes.string };






const Workplace = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchImages = async () => {
            try {
                const response = await axios.get(`${BackendUrl}/workplace/all`);
                setImages(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    const openModal = (index) => {
        setSelectedImage(index);
    };

    const closeModal = () => {
        setSelectedImage(null);
    };

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('overlay')) {
            closeModal();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'ArrowRight' && selectedImage < images.length - 1) {
            setSelectedImage((prevIndex) => prevIndex + 1);
        } else if (event.key === 'ArrowLeft' && selectedImage > 0) {
            setSelectedImage((prevIndex) => prevIndex - 1);
        } else if (event.key === 'Escape') {
            closeModal();
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        touchMove: true,
        responsive: [
            {
                breakpoint: 768, // Adjust this breakpoint as needed
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '30vh',
        position: 'relative',
    };

    useEffect(() => {
        if (selectedImage !== null) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [selectedImage]);

    return (
        <div onKeyDown={handleKeyDown} tabIndex="0">
            <Navbar />

            <section>
                <div style={containerStyle}>
                    <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center font-poppins">
                        <h2 className="text-slate-100 text-4xl font-bold uppercase">Our Workplace</h2>
                    </div>
                </div>
            </section>

            <div className=" font-poppins container mx-auto px-2 sm:px-5 py-2 lg:px-32 lg:pt-12 max-w-screen-xl">
                {/*<div className="text-center mb-5">*/}
                {/*    <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">Our Workplace</h3>*/}
                {/*</div>*/}
                <div className="flex flex-wrap -m-2">
                    {images.map((item, index) => (
                        <div key={index} className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
                            <img
                                alt={`gallery-${index}`}
                                className="block h-full w-full rounded-lg object-cover object-center mb-2 cursor-pointer"
                                src={`${BackendUrl}/${item.webpFilename}`}
                                onClick={() => openModal(index)}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {selectedImage !== null && (
                <div
                    className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 overlay "
                    onClick={handleOverlayClick}
                >
                    <div className="relative max-w-screen-sm mx-auto">
                        <Slider {...settings} initialSlide={selectedImage}>
                            {images.map((item, index) => (
                                <div key={index} className="w-full h-full">
                                    <img
                                        alt={`modal-${index}`}
                                        className="block w-full h-full object-cover mx-auto sm:w-full sm:h-full md:w-full md:h-full lg:w-full lg:h-full xl:w-full xl:h-full"
                                        src={`${BackendUrl}/${item.webpFilename}`}
                                    />
                                </div>
                            ))}
                        </Slider>
                        <button
                            className="absolute top-2 right-2 text-red-500 cursor-pointer flex items-center justify-center p-2 bg-white rounded-full"
                            onClick={closeModal}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>


                </div>
            )}

            <Footer />
        </div>
    );
};

export default Workplace;
