import React, {useEffect, useState} from 'react';
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import axios from "axios";
import BackendUrl from "../Backend-url";
import { useParams } from 'react-router-dom';

const JobDetails = () => {
    const [vacancyDetails, setVacancyDetails] = useState([]);
    const [loading, setLoading] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        const fetchVacancyDetails = async () => {
            try {
                const response = await axios.get(`${BackendUrl}/vacancy/${id}`);
                console.log(response)
                setVacancyDetails(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchVacancyDetails();
    }, [id]);

    return (
        <div>
            {/*navigation bar */}
            <Navbar/>

            <section className="mt-12 mx-auto px-4 max-w-screen-xl md:px-8">

                <div className="text-center mb-5">
                    <h1 className="text-3xl text-gray-800 font-semibold">
                        Job Vacancy
                    </h1>
                </div>
                {loading ? (
                    <p>Loading...</p>
                ) : (

                <div className="JobDetails bg-gray-100 p-4 flex flex-wrap">
                    <div className="JobShow w-full md:w-6/12 mb-4 md:mb-0 border-5 border-amber-900 mr-12">
                        <div className="JobShowDetails p-4 ">
                            {/*<h4 className="text-2xl font-bold">{vacancyDetails.vacancy_name}</h4>*/}
                            {/*<p className="text-gray-600">{vacancyDetails.country}</p>*/}
                            {/*<p className=" text-gray-600" dangerouslySetInnerHTML={{ __html: (vacancyDetails.description)}}></p>*/}

                            {/*<p className="text-gray-600">{vacancyDetails.age_range} Years</p>*/}

                            {/*<p className="text-gray-600">- Salary: {vacancyDetails.price}</p>*/}
                            {/*<p className="text-gray-600">- Type: {vacancyDetails.employment_type}</p>*/}
                            {/*<p className="text-gray-600">- Experience required: {vacancyDetails.required_experience}</p>*/}
                            {/*<p className="text-gray-600">To apply...</p>*/}
                            {/*<ul className="list-disc ml-6">*/}
                            {/*    <li>Call now 0372233421</li>*/}
                            {/*    <li>Gmail: <a href="mailto:cv.teegeeagency@gmail.com" className="text-blue-500">cv.teegeeagency@gmail.com</a></li>*/}
                            {/*    <li>Whatsapp 076 436 8860</li>*/}
                            {/*    <li>Web: <a href="https://www.facebook.com/groups/www.teegeeagency.lk" className="text-blue-500">www.teegeeagency.lk</a></li>*/}
                            {/*</ul>*/}
                            {/*<p className="text-gray-600">Tee Gee Agency (Pvt) Ltd. Manpower Recruitment &amp; Allied services #TeeGee</p>*/}
                            {/*<p className="text-right text-green-500 font-bold">{vacancyDetails.price}</p>*/}
                            {/* ... (other HTML content) */}

                            <img src={`${BackendUrl}/${vacancyDetails.imagePath}`}/>



                        </div>
                    </div>

                    <div className="JobForm w-full md:w-5/12">
                        <form method="post" action="" encType="multipart/form-data" className="mt-4">
                            <div className="FormIn">
                                <h4 className="text-2xl font-bold">Apply Now</h4>
                                <label htmlFor="name" className="block">
                                    Name:
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    required=""
                                    placeholder="Enter Your Name"
                                    className="w-full p-2 border border-gray-300 rounded-md mb-2"
                                />

                                <label htmlFor="phone" className="block">
                                    Phone:
                                </label>
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    required=""
                                    placeholder="Enter Your Contact Number"
                                    className="w-full p-2 border border-gray-300 rounded-md mb-2"
                                />

                                <label htmlFor="email" className="block">
                                    Email:
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    required=""
                                    placeholder="Enter Your Email"
                                    className="w-full p-2 border border-gray-300 rounded-md mb-2"
                                />

                                <label htmlFor="message" className="block">
                                    Message:
                                </label>
                                <textarea
                                    name="message"
                                    id="message"
                                    required=""
                                    className="w-full p-2 border border-gray-300 rounded-md mb-2"
                                ></textarea>

                                <label htmlFor="attachment" className="block">
                                    Upload your CV:
                                </label>
                                <input
                                    type="file"
                                    name="attachment"
                                    id="attachment"
                                    required=""
                                    className="w-full p-2 border border-gray-300 rounded-md mb-4"
                                />
                                <button type="submit" className="cvsubmit bg-blue-500 text-white py-2 px-4 rounded-md">
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                    )
                }
            </section>
            {/* Footer section*/}
            <Footer/>
        </div>
    );
};

export default JobDetails;
