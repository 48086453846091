import React, {useEffect, useState} from 'react';
import Footer from "../component/footer";
import Navbar from "../component/navbar";

import Director from '../images/aboutus/director.webp';
import Manager from '../images/aboutus/manager.webp';



import IT_Manager from '../images/aboutus/abbadh.webp';
import Embassy_Representative from '../images/aboutus/niroshan.webp';
import Marketing_Executive from '../images/aboutus/Akeel.webp';
import Receptionist from '../images/aboutus/receptionist.webp';
import HR_Assistant_Middle_East from '../images/aboutus/sclark.webp';
import HR_Assistant_Middle_East_2 from '../images/aboutus/aacountassistant.webp';
import Account_Clark from '../images/aboutus/computerop.webp';
import AOS from "aos";

import backgroundImage from '../images/aboutus/cover.webp';
import Bottomto_up from "../component/bottomto_up";
// import Toastify from "./toastify";  // Import your background image

const AboutUs = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);

    const [isPopupVisible, setPopupVisible] = useState(false);

    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    const team = [
        {
            avatar: IT_Manager,
            name: "MR M.G.M. Abbadh",
            title: "IT Manager"
        },
        {
            avatar: Embassy_Representative,
            name: "Mr. V. Niroshan",
            title: "Embassy Representative"
        },
        {
            avatar: Marketing_Executive,
            name: "Mr. M.S. Mohammed Akeel",
            title: "Marketing Executive"
        },

        {
            avatar: HR_Assistant_Middle_East,
            name: "Mr. M.T.M. Siyal",
            title: "HR Assistant Middle East"
        },

        {
            avatar: HR_Assistant_Middle_East_2,
            name: "Miss. B.M.H.K. Shashikala",
            title: "HR Assistant Middle East"
        },

        {
            avatar: Receptionist,
            name: "Miss. H.D.C.S. Jayarathna",
            title: "Receptionist"
        },
        {
            avatar: Account_Clark,
            name: "Miss. L.A.E. Wasana Liyanage",
            title: "Account Clark"
        },
    ]

    let director;
    return (


        <div>

            <Navbar/>
            <Bottomto_up/>
            {/*<Toastify/>*/}

        {/* about containt */}

            <section className="relative bg-[#343434] z-1 font-poppins">
                <div className="relative max-w-7xl mx-auto z-10 py-28 bg-gray-900" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "center" }}>
                    <div className="max-w-7xl mx-auto text-center bg px-4 md:px-8">

                        <h3 className=" text-3xl font-semibold sm:text-4xl text-blue-100 font-semibold pb-6" data-aos="zoom-in">About Us</h3>
                        <figure>
                            <blockquote>
                                <p className="text-white text-xl font-semibold sm:text-2xl " data-aos="zoom-in">
                                    “TeeGee Agency (PVT) Ltd has been spreading our reputation for 38 years since 1989 as a reputed recruitment consultancy company in Sri Lanka.
                                    For 38 years in our journey so far, we have helped our clients to fulfill their lives with meaningful employment and development opportunities.
                                    In our journey so far, we have hired thousands of people for a number of industries such as construction, shipping, automobiles, hotel industry, transportation, hospitality service and domestic service.
                                    TeeGee Agency (PVT) Ltd is listed as an agency that provides services to a large number of countries around the world.
                                    We are committed to finding the best choice for all of the people we interact with.
                                    It is our primary aim to provide more than what we expect from all personnel by being creative,
                                    efficient and dedicated to them and building effective relationships with them“
                                </p>
                            </blockquote>

                        </figure>

                    </div>
                {/*<div className="absolute inset-0 max-w-md mx-auto h-72 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>*/}
                </div>
                {/*<div className="absolute top-0 w-full h-full" style={{ background: "linear-gradient(268.24deg, rgba(59, 130, 246, 0.76) 50%, rgba(59, 130, 246, 0.545528) 80.61%, rgba(55, 48, 163, 0) 117.35%)" }}></div>*/}

            </section>


        {/*    vision and mission*/}



            <div className="container my-24  md:px-6 font-poppins">



                <section class=" max-w-screen-xl mx-auto bg-gray-500" data-aos="zoom-in">
                    <div class="flex flex-wrap">
                        {/*<div class="w-full shrink-0 grow-0 basis-auto md:w-2/12 lg:w-3/12">*/}
                        {/*    <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(23).jpg"*/}
                        {/*         class="mb-6 w-full rounded-lg shadow-lg dark:shadow-black/20" alt="Avatar" />*/}
                        {/*</div>*/}

                        <div class="w-full shrink-0 grow-0 basis-auto text-center md:w-12/12 md:pl-6 md:text-left lg:w-12/12 my-5">
                            <h5 class="mb-6 text-xl font-semibold">VISION</h5>
                            {/*<ul class="list-inside mb-6 flex justify-center space-x-4 md:justify-start">*/}
                            {/*    <a href="#!">*/}

                            {/*        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4"*/}
                            {/*             fill="currentColor" viewBox="0 0 24 24">*/}
                            {/*            <path*/}
                            {/*                d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />*/}
                            {/*        </svg>*/}
                            {/*    </a>*/}
                            {/*    <a href="#!">*/}

                            {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"*/}
                            {/*             class="h-4 w-4">*/}
                            {/*            <path fill="currentColor"*/}
                            {/*                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />*/}
                            {/*        </svg>*/}
                            {/*    </a>*/}
                            {/*    <a href="#!">*/}

                            {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"*/}
                            {/*             class="h-3.5 w-3.5">*/}
                            {/*            <path fill="currentColor"*/}
                            {/*                  d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />*/}
                            {/*        </svg>*/}
                            {/*    </a>*/}
                            {/*</ul>*/}
                            <p>
                                The vision of our foreign employment agency is to be the leading provider of recruitment services for employers seeking to hire foreign workers.
                                We aim to simplify and streamline the process for our clients and provide a seamless and stress-free experience for both employers and job seekers.
                            </p>
                        </div>
                    </div>
                </section>


                <section className=" max-w-screen-xl mx-auto bg-gray-500 my-2 font-poppins" data-aos="zoom-in">
                    <div className="flex flex-wrap">
                        {/*<div class="w-full shrink-0 grow-0 basis-auto md:w-2/12 lg:w-3/12">*/}
                        {/*    <img src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(23).jpg"*/}
                        {/*         class="mb-6 w-full rounded-lg shadow-lg dark:shadow-black/20" alt="Avatar" />*/}
                        {/*</div>*/}

                        <div className="w-full shrink-0 grow-0 basis-auto text-center md:w-12/12 md:pl-6 md:text-left lg:w-12/12 my-5">
                            <h5 className="mb-6 text-xl font-semibold">MISSION</h5>
                            {/*<ul class="list-inside mb-6 flex justify-center space-x-4 md:justify-start">*/}
                            {/*    <a href="#!">*/}

                            {/*        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4"*/}
                            {/*             fill="currentColor" viewBox="0 0 24 24">*/}
                            {/*            <path*/}
                            {/*                d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z" />*/}
                            {/*        </svg>*/}
                            {/*    </a>*/}
                            {/*    <a href="#!">*/}

                            {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"*/}
                            {/*             class="h-4 w-4">*/}
                            {/*            <path fill="currentColor"*/}
                            {/*                  d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z" />*/}
                            {/*        </svg>*/}
                            {/*    </a>*/}
                            {/*    <a href="#!">*/}

                            {/*        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"*/}
                            {/*             class="h-3.5 w-3.5">*/}
                            {/*            <path fill="currentColor"*/}
                            {/*                  d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />*/}
                            {/*        </svg>*/}
                            {/*    </a>*/}
                            {/*</ul>*/}
                            <p>
                                Our mission is to connect talented workers with job opportunities that align with their skills and career goals,
                                while ensuring that employers have access to a diverse and high-quality pool of candidates.
                            </p>
                        </div>
                    </div>
                </section>



            </div>


        {/*    ceo */}


            <div className="container font-poppins px-4 md:px-6">

                <section className=" max-w-screen-xl mx-auto">
                    {/*<h2 className="mb-16 text-center text-2xl font-bold">Latest articles</h2>*/}

                    <div className="mb-16 flex flex-wrap">
                        <div className="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6" data-aos="fade-right">
                            <div
                                className="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src={Director} className="" alt="Louvre" />
                                <a href="#!">
                                    <div
                                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6">
                            <h3 className="mb-4 text-2xl font-bold" data-aos="fade-left">Al Haj Dr.A.R M. Ghouse</h3>
                            <div className="mb-3 flex items-center text-sm font-medium text-primary dark:text-primary-400"data-aos="zoom-in">
                                {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"*/}
                                {/*     stroke="currentColor" className="mr-2 h-5 w-5">*/}
                                {/*    <path stroke-linecap="round" stroke-linejoin="round"*/}
                                {/*          d="M12.75 3.03v.568c0 .334.148.65.405.864l1.068.89c.442.369.535 1.01.216 1.49l-.51.766a2.25 2.25 0 01-1.161.886l-.143.048a1.107 1.107 0 00-.57 1.664c.369.555.169 1.307-.427 1.605L9 13.125l.423 1.059a.956.956 0 01-1.652.928l-.679-.906a1.125 1.125 0 00-1.906.172L4.5 15.75l-.612.153M12.75 3.031a9 9 0 00-8.862 12.872M12.75 3.031a9 9 0 016.69 14.036m0 0l-.177-.529A2.25 2.25 0 0017.128 15H16.5l-.324-.324a1.453 1.453 0 00-2.328.377l-.036.073a1.586 1.586 0 01-.982.816l-.99.282c-.55.157-.894.702-.8 1.267l.073.438c.08.474.49.821.97.821.846 0 1.598.542 1.865 1.345l.215.643m5.276-3.67a9.012 9.012 0 01-5.276 3.67m0 0a9 9 0 01-10.275-4.835M15.75 9c0 .896-.393 1.7-1.016 2.25" />*/}
                                {/*</svg>*/}
                                Managing Director
                            </div>

                            <p className="mb-6 text-neutral-500 dark:text-neutral-500 jus text-justify" data-aos="fade-up">
                                I take great pleasure in introducing that we are one of the leading and government approved manpower recruitment agencies in Sri Lanka
                                under labour licence No, 0893, issued from SLBEF “SRI LANKA BUREAU OF FOREIGN EMPLOYMENT” in the year of 1989. Our office has located at the hub of KURUNEGALA
                                city of North West Province of Sri Lanka which is 85 KM far from Colombo. Our office is modernized with all the facilities to operate manpower recruiting business
                                as it has separate sector to recruit for each and every country.
                                It has also a particular division to recruit domestic helpers such as Housemaids, House Drivers, Baby Sitters, House nurses, female cleaners etc.
                            </p>
                            <p className="cursor-pointer text-blue-500"data-aos="fade-up" onClick={togglePopup}>
                                more
                            </p>

                            {isPopupVisible && (
                                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
                                    <div className="bg-white p-8 md:my-8 rounded-md relative popup-container mx-4 md:mx-0" style={{ maxHeight:'600px', height: 'auto', width: '90%', maxWidth: '900px', overflowY: 'auto' }}>
                                        <button
                                            onClick={togglePopup}
                                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                                        >
                                            {/* Close icon (SVG) */}
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>

                                        {/* Your detailed content goes here */}


                                        <div className="relative">
                                            <div className="sticky top-0">
                                                <h2 className="text-2xl font-semibold mb-4 ">
                                                    Managing Director
                                                </h2>
                                                <p className="text-gray-600 mb-4 text-justify">
                                                    I take great pleasure in introducing that we are one of the leading and government approved manpower recruitment agencies in Sri Lanka under
                                                    labour licence No, 0893, issued from SLBEF “SRI LANKA BUREAU OF FOREIGN EMPLOYMENT” in the year of 1989.<br/><br/>

                                                    Our office has located at the hub of KURUNEGALA city of North West Province of Sri Lanka which is 85 KM far from Colombo. Our
                                                    office is modernized with all the facilities to operate manpower recruiting business as it has separate sector to recruit for each and every country.
                                                    It has also a particular division to recruit domestic helpers such as Housemaids, House Drivers, Babysitters, House nurses, female cleaners etc.<br/><br/>

                                                    We have supplied workforce to kingdom of Saudi Arabia, United Arab Emirates, State of Kuwait, State of Qatar, Kingdom of Bahrain, Lebanon, Jordan,
                                                    Syria, Oman, Yemen, Malaysia etc, The people we recruited are for the trade of hospitality, technical, construction, (building and roads), maintenance,
                                                    cleaning, transportation, catering and hospital etc. We have also supplied staff for several five-star hotels in the kigdom of Saudi Arabia and other countries.<br/><br/>

                                                    The recruitment business should be a win-win situation for both parties. Just assure clients need our experience and services, whist understanding their needs.
                                                    We turn as a professional agency provide the best manpower available in that country, taking into account the ever-growing need in this fast changing world.<br/><br/>

                                                    Through the experience and knowledge of manpower recruiting, we have so far gained; we are now in a position to handle all kind of manpower recruitment
                                                    from Sri Lanka within a short period of time with almost care and attention.<br/><br/>

                                                    With regard to our talent and experience, you may safely depend on us, as we are processing long experience of manpower recruiting to many large groups
                                                    of companies, hotels and establishments in the Middle East. You may kindly verify us from our prestigious clients, whose names have been mentioned in this booklet.<br/><br/>

                                                    We assure all clients of our best attention and services at all the time.<br/><br/>

                                                    Dr. A.R. Mohammed Ghouse<br/>
                                                    Managing Director / Licence
                                                </p>
                                            </div>
                                        </div>


                                    </div>
                                </div>



                            )}


                        </div>
                    </div>

                    <div className="mb-16 flex flex-wrap lg:flex-row-reverse">
                        <div className="mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6">
                            <div
                                className="ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat shadow-lg dark:shadow-black/20"
                                data-te-ripple-init data-te-ripple-color="light">
                                <img src={Manager} className="w-full" alt="Louvre" />
                                <a href="#!">
                                    <div
                                        className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className="w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pr-6">
                            <h3 className="mb-4 text-2xl font-bold" data-aos="fade-right">Mrs. N. Renoza Ghouse</h3>
                            <div className="mb-3 flex items-center text-sm font-medium text-primary dark:text-primary-400" data-aos="zoom-in">
                                {/*<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"*/}
                                {/*     stroke="currentColor" className="mr-2 h-4 w-4">*/}
                                {/*    <path stroke-linecap="round" stroke-linejoin="round"*/}
                                {/*          d="M9.53 16.122a3 3 0 00-5.78 1.128 2.25 2.25 0 01-2.4 2.245 4.5 4.5 0 008.4-2.245c0-.399-.078-.78-.22-1.128zm0 0a15.998 15.998 0 003.388-1.62m-5.043-.025a15.994 15.994 0 011.622-3.395m3.42 3.42a15.995 15.995 0 004.764-4.648l3.876-5.814a1.151 1.151 0 00-1.597-1.597L14.146 6.32a15.996 15.996 0 00-4.649 4.763m3.42 3.42a6.776 6.776 0 00-3.42-3.42" />*/}
                                {/*</svg>*/}
                                Genaral Manager
                            </div>
                            {/*<p className="mb-6 text-sm text-neutral-500 dark:text-neutral-400">*/}
                            {/*    Published <u>12.01.2022</u> by*/}
                            {/*    <a href="#!">Anna Doe</a>*/}
                            {/*</p>*/}
                            <p className="text-neutral-500 dark:text-neutral-500 text-justify" data-aos="fade-up">
                                As the General Manager of a foreign employment agency, I would like to take this opportunity to express my appreciation for the trust and confidence
                                placed in us by our clients and partners. Our agency is dedicated to providing high-quality recruitment services for employers seeking to hire foreign workers,
                                and we are committed to making the process as smooth and efficient as possible. Our team of experienced professionals has a deep understanding of the recruitment process,
                                and we work tirelessly to provide personalized services that meet the unique needs of each of our clients.
                                Whether you are seeking to hire a single worker or a large workforce, we are here to support you every step of the way.
                            </p>
                        </div>
                    </div>


                </section>

            </div>




        {/* team */}
        <section className="py-14 font-poppins">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="max-w-xl mx-auto sm:text-center" data-aos="zoom-in">
                    <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">
                        Our team
                    </h3>
                    <p className="text-gray-600 mt-3">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.
                    </p>
                </div>
                <div className="mt-12">
                    <ul className="grid gap-8 sm:grid-cols-3 md:grid-cols-4">
                        {
                            team.map((item, idx) => (
                                <li key={idx}>
                                    <div className="w-full h-72 sm:h-52 md:h-72" data-aos="zoom-in">
                                        <img
                                            src={item.avatar}
                                            className="w-full h-full object-cover object-center shadow-md rounded-xl"
                                            alt=""
                                        />
                                    </div>
                                    <div className="mt-4" data-aos="fade-up">
                                        <h4 className="text-lg text-gray-700 font-semibold">{item.name}</h4>
                                        <p className="text-indigo-600">{item.title}</p>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>

            <Footer/>

        </div>

    )
};

export default AboutUs;