import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

const BottomtoUp = () => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsButtonVisible(true);
        } else {
            setIsButtonVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            {/* Your main content goes here */}

            {/* Button for scrolling to top */}
            {isButtonVisible && (
                <button
                    onClick={scrollToTop}
                    className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-lg cursor-pointer z-50 w-10 h-10"
                >
                    <FontAwesomeIcon icon={faArrowUp} />
                </button>
            )}
        </div>
    );
};

export default BottomtoUp;
