import React from 'react';
import Navbar from "../component/navbar";
import HomepageCarousel from "../component/homepage-carousel";
import HomepageIndustriesClients from "../component/homepage-industries&clients";
import Footer from "../component/footer";
import About from "../component/Homepage/about";
import Services from "../component/Homepage/services";
import Time_navbar from "../component/time_navbar";
import Bottomto_up from "../component/bottomto_up";
import SocialMediaBar from "../component/social_media_bar";


import '../css/Font/font.css';
import SocialMediaLinks from "../component/social_media_bar";

const Homepage = () => {
    return (
        <div>
            <header>
                <Navbar/>
            </header>


            {/*<Bottomto_up/>*/}
            {/*<SocialMediaBar/>*/}
            {/*<Time_navbar/>*/}


            <div>
                <HomepageCarousel/>
                <HomepageIndustriesClients/>
                <About/>
                <Services/>
            </div>

            <footer>
                <Footer/>
            </footer>

        </div>
    );
};

export default Homepage;