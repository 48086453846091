import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import axios from "axios";
import BackendUrl from "../Backend-url";
import bannerImage from "../images/job-listing/job-listing-wallpaper.webp";

const ProjectDetails = () => {
    const { id } = useParams();
    const [project, setProject] = useState({});
    const [imageUrls, setImageUrls] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${BackendUrl}/gallery/partner-images/${id}`);
                setProject(response.data);
                setLoading(false);
                // Once project data is fetched, fetch the image URLs
                await fetchImageUrls(response.data.directoryPath);
            } catch (error) {
                console.error('Error fetching project data:', error);
                setLoading(false);
            }
        };

        fetchProjectData();
    }, [id]);

    const fetchImageUrls = async (directoryPath) => {
        try {
            const response = await axios.get(`${BackendUrl}/${directoryPath}`); // Adjust the URL to correctly fetch images
            setImageUrls(response.data);
        } catch (error) {
            console.error('Error fetching image URLs:', error);
        }
    };


    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '30vh',
        position: 'relative',
    };

    return (
        <>
            <Navbar />
            <section>
                <div style={containerStyle}>
                    <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center font-poppins">
                        <h2 className="text-slate-100 text-4xl font-bold uppercase">{project.title}</h2>
                    </div>
                </div>
            </section>
            <section>
                <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center ">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {imageUrls.map((imageUrl, index) => (
                            <div className="relative" key={index}>
                                <img src={`${BackendUrl}/${imageUrl}`} alt={`Image ${index}`} className="w-full h-auto" />
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default ProjectDetails;
