import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from "react-toastify";
import {HomeIcon} from "@heroicons/react/24/solid";

import {useNavigate} from "react-router-dom";
import logo1 from "../images/job-listing/12345.jpg";
import Time_navbar from "../component/time_navbar";


const Applicants = () => {
    const navigate = useNavigate();
    const [Client, setClient] = useState([]);
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000); // Update every second

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    const formattedTime = currentTime.toLocaleTimeString();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedClient = localStorage.getItem('client');
                setClient(JSON.parse(storedClient));

            } catch (error) {
                navigate('/login');
                console.error('Error fetching data:', error);

                // Retry after 5 seconds
                setTimeout(() => {
                    fetchData();
                }, 5000);

            }
        };

        fetchData();
    }, []);

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Applicants', url: '/applicant' },
    ];

    return (
        <>
            <ToastContainer
                position="top-right" // Adjust position as needed
                autoClose={1000} // Close after 2 seconds
                hideProgressBar={false} // Show progress bar
                newestOnTop={false}
                closeOnClick // Close toast on click
                rtl={false} // Right to left
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <div className="bg-blue-950 w-full h-[100vh] flex flex-col justify-start items-center">
                <div className="w-9/12">

                    <Time_navbar/>

                    {/*<div className="col-span-12 mt-0">*/}
                    {/*    <div className="grid gap-2 grid-cols-1 lg:grid-cols-2">*/}
                    {/*        <div className="mt-3">*/}
                    {/*            /!*<Breadcrumb items={breadcrumbItems} />*!/*/}
                    {/*        </div>*/}
                    {/*        <div className="mt-3 relative">*/}
                    {/*            {Client.length === 0 ? (*/}
                    {/*                    <div className="absolute right-0">*/}
                    {/*                        Welcome, Loading...*/}
                    {/*                    </div>*/}
                    {/*                ):*/}
                    {/*                (*/}
                    {/*                    <div className="absolute right-0 capitalize">*/}
                    {/*                        Welcome, <strong>{Client.username} </strong> | {formattedTime}*/}
                    {/*                    </div>)}*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}


                    <section className="bg-stone-50 rounded-[15px] px-8 pb-4 w-11/12 h-auto  sm:top-[450px] mx-auto max-w-7xl mt-3">

                        <div className="flex flex-col sm:flex-row px-2">
                            <input
                                type="text"
                                name="title"
                                id="title"
                                autoComplete="title"


                                className="block mt-3 w-full sm:w-1/3 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"
                                placeholder="Find the job title, company, keyword"
                            />
                            <select
                                name="country"
                                id="country"
                                autoComplete="country"
                                className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"

                            >
                                <option value="">Select Country</option>

                            </select>
                            <select
                                name="employmentType"
                                id="employmentType"
                                autoComplete="employmentType"
                                className="mt-3 sm:mt-0 ml-0 sm:ml-3 block w-full sm:w-1/4 rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-600 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-stone-100"

                            >
                                <option value="">Select Employment Type</option>

                            </select>
                            <button
                                className="mt-3 sm:mt-0 ml-0 sm:ml-3 bg-blue-900 hover:bg-blue-700 text-white font-medium py-2 px-4 rounded disabled">
                                SEARCH
                            </button>
                        </div>
                    </section>

                    <div className="flex flex-col md:flex-row md:justify-between mt-2">
                        <div className="w-full md:w-3/12 h-auto bg-white mb-2 md:mb-0">
                            {/* First new div */}
                            <div className="bg-red-500 h-[52px] m-1">
                                {/* Content of the first new div */}
                            </div>
                            {/* Second new div */}
                            <div className="bg-yellow-500 h-[52px]  m-1">
                                {/* Content of the second new div */}
                            </div>
                        </div>

                        <div className="w-full md:w-9/12 h-auto bg-green-500 ">
                            <div className="bg-orange-200 h-[52px]  m-1">
                                {/* Content of the first new div */}
                                <p className="text-black">This is some text inside the div.</p>

                            </div>

                            {/* Second new div */}
                            <div className="bg-indigo-700 h-auto m-1 flex flex-col">
                                <div className="bg-white p-2 rounded-lg shadow-md  flex flex-col md:flex-row md:justify-between mt-2">
                                    <div className="w-full md:w-3/12 h-auto md:mb-0 ">
                                        <img
                                            src={logo1}
                                            alt="Company Logo"
                                            className="border border-gray-800 p-1 max-w-full"
                                            style={{ height: 'auto' }}
                                        />

                                    </div>

                                    <div className="w-full md:w-9/12 h-auto bg-gray-500 md:mb-0">
                                        {/* Div for Name */}
                                        <div className="border-b border-gray-400 pb-2">
                                            <h2 className="text-lg font-semibold text-gray-800">Name</h2>
                                            {/* Content related to Name */}
                                        </div>

                                        {/* Div for Experian */}
                                        <div className="border-b border-gray-400 py-2 md:flex md:items-center md:justify-between">
                                            {/* Content related to Experian on the left */}
                                            <div>
                                                <h2 className="text-lg font-semibold text-gray-800">Civil Status 1</h2>
                                            </div>

                                            {/* Text in the middle */}
                                            <div className=" md:block">
                                                <h2 className="text-lg font-semibold text-gray-800">Civil Status 2</h2>
                                            </div>

                                            {/* Additional content on the right */}
                                            <div className=" md:block">
                                                <h2 className="text-lg font-semibold text-gray-800">Civil Status 3</h2>
                                            </div>
                                        </div>


                                        {/* Div for Civil Status */}
                                        <div className="border-b border-gray-400 py-2">
                                            <h2 className="text-lg font-semibold text-gray-800">Civil Status 4</h2>
                                            {/* Content related to Civil Status */}
                                        </div>

                                        {/* Div for Additional Details */}
                                        <div className="py-2 flex flex-col md:flex-row md:justify-between">
                                            <button className="bg-blue-500 text-white px-4 py-2 rounded-md mb-2 md:mb-0 md:mr-2">
                                                Download CV
                                            </button>

                                            {/* More Details Button */}
                                            <button className="bg-green-500 text-white px-4 py-2 rounded-md">
                                                More Details
                                            </button>
                                        </div>
                                    </div>







                                </div>




                            </div>


                        </div>
                    </div>







                </div>
            </div>
        </>
    );
};

export default Applicants;