import React, {useEffect, useState} from 'react';
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import Bottomto_up from "../component/bottomto_up";
import bannerImage from "../images/job-listing/job-listing-wallpaper.webp";
import AOS from "aos";

const OurServices = () => {

    const [popupVisible, setPopupVisible] = useState(false);
    const [selectedService, setSelectedService] = useState(null);

    const openPopup = (service) => {
        setSelectedService(service);
        setPopupVisible(true);
    };

    const closePopup = () => {
        setSelectedService(null);
        setPopupVisible(false);
    };
    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '30vh',
        position: 'relative',
    };

    const features = [
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                </svg>,
            title: "Staff Requirements",
            desc: "TeeGee Agency (PVT) Ltd clears the path to direct and guarantee a low risk hiring strategy to handle timely employee and labor. We offer advice on the advantages of" +
                " investment and assistance in successfully getting the jobs currently available in the organization.",

            more_desc: "TeeGee Agency (PVT) Ltd clears the path to direct and guarantee a low risk hiring strategy to handle timely employee and labor. " +
                "We offer advice on the advantages of investment and assistance in successfully getting the jobs currently available in the organization." +
                " In order to maintain the current personnel and train the appropriate job function to a select staff cadre, " +
                "our team does a complete analysis to identify the skills, frequency, and delivery. As one of the top recruiters in Sri Lanka," +
                " we do research on the influence of business accomplishments and employer and employee equity. " +
                "TGL represents the company's primary goals and objectives by providing the following benefits through the service: " +
                "*How to stay current with technical and industry developments *strategies for beating out the competition. *strengthening staff" +
                " skills and filling in skill shortages. *An evaluation and incentive program will be put in place to increase advanced personnel " +
                "abilities *By taking into account key business perspectives from the perspective of the employee, you may increase personal and professional happiness. " +
                "*making the most use possible of the skills of the current workforce, " +
                "while also changing progressive output. Contact our team of knowledgeable recruiters to learn more about the continuing staffing needs we can meet for you."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                </svg>,
            title: "Visa Processing & Clearance Services",
            desc: "We at TeeGee Agency (PVT) Ltd are a network of licensed visa advisers, barristers, lawyers, registered migration agents, " +
                "and highly skilled processing personnel. We have expanded our services to include helping with visa applications.",
            more_desc: "We at TeeGee Agency (PVT) Ltd are a network of licensed visa advisers, barristers, lawyers, registered migration agents, and highly skilled processing personnel." +
                " We have expanded our services to include helping with visa applications. To guarantee a smooth procedure, every element in the supporting papers and visa application is carefully examined. Additionally," +
                "we provide Clearance services. Finalizing the paperwork and sparing the clients from laborious administrative procedures ensures the convenience of the consumers." +
                " We can streamline the procedure and guarantee there won't be any delays thanks to the experience and knowledge of our staff. " +
                "We handle every step of the procedure. Contact us for more information."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>,
            title: "HR Consultancy",
            desc: "We think that any human resource needs a human touch, which includes engaging relationships and human capital assurance. " +
                "One of the responsible manpower agencies in Sri Lanka, TeeGee helps businesses use this platform to connect with the right individuals.",
            more_desc: "We think that any human resource needs a human touch, which includes engaging relationships and human capital assurance. " +
                "One of the responsible manpower agencies in Sri Lanka, TeeGee helps businesses use this platform to connect with the right individuals. " +
                "TeeGee offers human resource models for the relevant businesses who are looking to fill open positions. This offers comfort, practicality, awareness," +
                " and sustainability in terms of keeping personnel on board for an extended length of time. Regarding open positions internationally, " +
                "our goal is to offer human capital solutions that increase productivity and actively carry out the company's vision and mission. We help our clients identify employee requirements, " +
                "develop an action plan, and promote change facilitation during business periods in order to raise staff morale. " +
                "The outcomes ensure businesses that are motivated to maintain a high level of productivity and efficiency while lowering staff turnover. " +
                "Regardless of the magnitude of the company's expansion, " +
                "TeeGee is prepared to give outsourcing and support for critical human resource tasks."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3" />
                </svg>,
            title: "Executive Search & Headhunting",
            desc: "One of the top headhunters in Sri Lanka, TeeGee Agency has kept its attention completely on its client while helping to describe the job position, " +
                "corporate culture, and workforce requirements through direct, personal-professional communication with the client.",
            more_desc: "One of the top headhunters in Sri Lanka, TeeGee Agency has kept its attention completely on its client while helping to describe the job position," +
                " corporate culture, and workforce requirements through direct, personal-professional communication with the client." +
                " A contractual connection is maintained by our team of headhunters with those who help fill senior and top management jobs. " +
                "Our choice is based on applicants who have successfully maintained their records of development and their reputations inside other significant corporations. " +
                "With the support of our clients and staff, TeeGee Agency is able to provide better and more effective headhunting services. " +
                "Our prestigious clients, who are interested in managing great knowledge for effective leadership and operations, outsource headhunting to TeeGee. " +
                "The headhunters are guaranteed warm, personable, forward-thinking, and helpful network connections." +
                " Once a qualified applicant is hired after a casual interview, the procedure is put into action."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" />
                </svg>,
            title: "Career Guidance & Trade Test",
            desc: "The finest recruiters in Sri Lanka provide advice on how to get the necessary experience to fill the appropriate jobs on the job market. " +
                "The difficult career selections made with TeeGee's system include consulting." ,
            more_desc: "The finest recruiters in Sri Lanka provide advice on how to get the necessary experience to fill the appropriate jobs on the job market." +
                " The difficult career selections made with TeeGee's system include consulting. Our solutions as a staffing agency focus on providing individualized assistance," +
                " tools to make career counseling accurate and consistent, and administering career exams to independent people making a significant change in their working lives. " +
                "Our career coaching includes aspects like creating a customized career model, providing analytical techniques to choose the appropriate job requirements, " +
                "and executing a modest research-driven framework to comprehend your employment demands. Our career advising knowledge fills the gap of many jobless people " +
                "who are eager to use their abilities on local and worldwide firms. Our career advice framework fills this gap by assisting with career planning, fitting assessment," +
                " and skill development. Our comprehensive worldwide career decision framework is correct to the last detail. " +
                "Let's get in touch with you to offer free career advice for better employee performance at your company."
        },
        {
            icon:
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z" />
                </svg>,
            title: "Worldwide Sourcing",
            desc: "One of the first employment agencies in Sri Lanka, TeeGee Agency (PVT) Ltd is prepared to screen out the best suitable applicant to match the job description for your business" ,
            more_desc: "One of the first employment agencies in Sri Lanka, TeeGee Agency (PVT) Ltd is prepared to screen out the best suitable applicant to match the job description for your business. Meeting people from other cultures, backgrounds, attitudes, customs, and social standards is a well-known challenge of working abroad. Our recruiting team creates a port to connect to groups of applicants who have submitted applications for the relevant positions. The procedure is run with the intention of conserving time and space for crucial candidate screening. Candidates and businesses have acquired demonstrated track records throughout the years via successful volume. Through a procedure, individuals who have been carefully chosen and are motivated by good aims are interviewed. The candidate is located, and replies are processed in accordance with background observations. " +
                "Our staff is skilled in conducting one-on-one personal screening and interviews while gathering the necessary data to help you perhaps achieve your company objectives. " +
                "We advise you to speak with our recruiting team professionals so they can provide you advice on how to hire permanent employees for your company." +
                " We hire from the many nations listed below."
        },


    ]

    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);


    return (
        <div>

            <Navbar/>
            <Bottomto_up/>

            <section>
                <div style={containerStyle}>
                    <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center">
                        <h2 className="text-slate-100 text-4xl font-bold uppercase"> Our Service</h2>
                    </div>
                </div>
            </section>

            <section className="py-8 font-poppins">
                <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                    <div className="relative max-w-2xl mx-auto sm:text-center">
                        <div className="relative ">
                            {/*<h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">*/}
                            {/*    Our Service*/}
                            {/*</h3>*/}
                            {/*<p className="mt-3">*/}
                            {/*    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec congue, nisl eget molestie varius, enim ex faucibus purus.*/}
                            {/*</p>*/}
                        </div>
                        <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(192, 132, 252, 0.2) 4.54%, rgba(232, 121, 249, 0.26) 34.2%, rgba(192, 132, 252, 0.1) 77.55%)" }}></div>
                    </div>
                    <div className="relative mt-12" >
                        <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                            {
                                features.map((item, idx) => (
                                    <li key={idx} className="bg-white space-y-3 p-4 border rounded-lg" data-aos="zoom-in">
                                        <div className="text-indigo-600 pb-3">
                                            {item.icon}
                                        </div>
                                        <h4 className="text-lg text-gray-800 font-semibold">
                                            {item.title}
                                        </h4>
                                        <p className="text-justify " style={{ height: '175px' }}>
                                            {item.desc}
                                        </p>

                                        <button
                                            onClick={() => openPopup(item)}
                                            className="mt-2 px-4 py-2 bg-indigo-600 text-white rounded-md"
                                        >
                                            More
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>

            {/* Popup view */}
            {popupVisible && selectedService && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-30">
                    <div className="bg-white p-8 md:my-8 rounded-md relative popup-container mx-4 md:mx-0" style={{ maxHeight:'600px', height: 'auto', width: '90%', maxWidth: '900px', overflowY: 'auto' }}>
                        <button
                            onClick={closePopup}
                            className="absolute top-2 right-2 text-gray-600 hover:text-gray-800"
                        >
                            {/* Close icon (SVG) */}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                className="w-6 h-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                        <h2 className="text-2xl font-semibold mb-4">
                            {selectedService.title}
                        </h2>
                        <p className="text-gray-600 mb-4 text-justify">{selectedService.more_desc}</p>
                    </div>
                </div>
            )}



            <Footer/>

        </div>
    );
};

export default OurServices;