import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
    ArrowPathIcon,
    Bars3Icon,
    ChartPieIcon,
    CursorArrowRaysIcon,
    FingerPrintIcon,
    SquaresPlusIcon,
    XMarkIcon,
    UserGroupIcon,
    DocumentIcon,
    GlobeAltIcon,
    BuildingLibraryIcon,
    MagnifyingGlassIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'

import logo from '../images/logo/logo.webp';
import '../css/components/navbar/navbar.css';


const Project = "project";

const companies = [
    { name: 'Partners', description: 'We guarantee a risk free hiring strategy', href: '/partners', icon: UserGroupIcon },
    { name: 'Projects', description: 'We can streamline the procedure and services', href: '/projects', icon: DocumentIcon },
]

const products = [
    { name: 'Analytics', description: 'Get a better understanding of your traffic', href: '#', icon: ChartPieIcon },
    { name: 'Engagement', description: 'Speak directly to your customers', href: '#', icon: CursorArrowRaysIcon },
    { name: 'Security', description: 'Your customers’ data will be safe and secure', href: '#', icon: FingerPrintIcon },
    { name: 'Integrations', description: 'Connect with third-party tools', href: '#', icon: SquaresPlusIcon },
    { name: 'Automations', description: 'Build strategic funnels that will convert', href: '#', icon: ArrowPathIcon },
]

const callsToAction = [
    { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
    { name: 'Contact sales', href: '#', icon: PhoneIcon },
]

const servicesToAction = [
    { name: 'Watch Services', href: '#', icon: PlayCircleIcon },
    { name: 'Contact Services', href: '#', icon: PhoneIcon },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)



    // Page links
    const AboutPage = "/about-us";
    const OurServices = "/our-services";
    const OurWorkplace = "/workplace";
    const Blog = "/blog";
    const Contactus = "/contact-us";
    const JobListing = "/job-listing";
    const pdf = "/pdf";
    const applicants = "/applicants";

    return (
        <header className="font-nunito-sans font-bold inset-x-0 pb-2 top-0 z-50 bg-white sm:bg-white md:bg-white lg:bg-transparent xl:bg-transparent" >


            {/* top nav */}
            <div className="bg-gray-200">
                <div className="container mx-auto max-w-7xl flex flex-wrap ">
                    <div className="w-full sm:w-1/3  hidden sm:block">
                        <a href="#" className="-m-1 p-1.5">
                            <img className="h-14 w-auto" src={logo} alt="Tee Gee Logo" />
                        </a>
                    </div>
                    <div className="w-full sm:w-2/3  hidden  sm:block">
                        {/* Flex container for three new columns */}
                        <div className="flex">
                            {/* Column 1 */}
                            <div className="w-1/3  ">
                                <div className="bs-info-content text-dark mt-2 ml-2">
                                    <div className="info-box d-flex">
                                        <span className="mt-2 text-center"><img className="w-75" src="http://tgluniversal.com/wp-content/uploads/2020/06/placeholder-1.png" alt="Reach Us" /></span>
                                        <div className="c-box pl-3 pr-3" style={{ fontSize: '12px' }}>
                                            <h6 className="title text-primary mb-0">Reach Us</h6>
                                            <a href="https://www.google.com/maps?ll=7.487612,80.36346&z=20&t=m&hl=en&gl=LK&mapclient=embed&cid=7459022123959426608">
                                                <p className="desc mb-0">Weavers Shopping Complex, 18 2nd Floor,<br /> Mihindu Mawatha, Kurunegala</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Column 2 */}
                            <div className="w-1/3  ">
                                <div className="bs-info-content text-dark mt-2 ml-2 w-auto">
                                    <div className="info-box d-flex">
                                        <span className="mt-2 text-center"><img className="w-75" src="http://tgluniversal.com/wp-content/uploads/2020/06/call-1.png" alt="Call Us Now" /></span>
                                        <div className="c-box pl-3 pr-3" style={{ fontSize: '12px' }}>
                                            <h6 className="title text-primary mb-0">Call Us Now</h6>
                                            <a href="tel:0094372223416">
                                                <p className="desc mb-0">+94 37 2 223 416</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Column 3 */}
                            <div className="w-1/3  ">
                                <div className="bs-info-content text-dark mt-2 ml-2">
                                    <div className="info-box d-flex">
                                        <span className="mt-2 text-center"><img className="w-75" src="http://tgluniversal.com/wp-content/uploads/2020/06/email-1.png" alt="Send Us Email" /></span>
                                        <div className="c-box pl-3 pr-3" style={{ fontSize: '12px' }}>
                                            <h6 className="title text-primary mb-0">Send Us Email</h6>
                                            <a href="mailto:teegeeagency@gmail.com">
                                                <p className="desc mb-0">teegeekg@gmail.com</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* second nav */}
            <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 pb-0 lg:px-8" aria-label="Global">
                <div className="lg:hidden sm:hidden">
                    <a href="#" className="-m-1.5 p-1.5">
                        <span className="sr-only">Tee Gee Agency (Pvt) Ltd</span>
                        <img className="h-12 w-auto" src={logo} alt="Tee Gee Logo" />
                    </a>
                </div>
                <div className="flex lg:hidden">
                    <button
                        type="button"
                        className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                        onClick={() => setMobileMenuOpen(true)}
                    >
                        <span className="sr-only">Open main menu</span>
                        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <Popover.Group className="hidden lg:flex lg:gap-x-12 ">
                    <a href="/" className="text-sm font-bold leading-6 text-blue-950 uppercase">
                        Home
                    </a>

                    {/* about page*/}

                    <a href={AboutPage} className="text-sm font-bold leading-6 text-blue-950 uppercase">
                        About
                    </a>
                    {/* Services page*/}
                    <a href={OurServices} className="text-sm font-bold leading-6 text-blue-950 uppercase">
                        Services
                    </a>



                    <Popover className="relative">
                        {/* Companise page*/}
                        <Popover.Button className="flex items-center gap-x-1 text-sm font-bold leading-6 text-blue-950 uppercase">
                            Companies
                            <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                        </Popover.Button>

                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                                <div className="p-4">
                                    {companies.map((item) => (
                                        <div
                                            key={item.name}
                                            className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                                        >
                                            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                                                <item.icon className="h-6 w-6 text-gray-600 group-hover:text-indigo-600" aria-hidden="true" />
                                            </div>
                                            <div className="flex-auto">
                                                <a href={item.href} className="block font-bold text-gray-900">
                                                    {item.name}
                                                    <span className="absolute inset-0" />
                                                </a>
                                                <p className="mt-1 text-gray-600">{item.description}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </Popover.Panel>
                        </Transition>
                    </Popover>


                    {/* job Listings page*/}
                    <a href={JobListing} className="text-sm font-bold leading-6 text-blue-950 uppercase">
                        job Listings
                    </a>
                    {/* OurWorkplace*/}
                    <a href={OurWorkplace} className="text-sm font-bold leading-6 text-blue-950 uppercase">
                        Our Workplace
                    </a>
                    {/* Blog page*/}
                    <a href={Blog} className="text-sm font-bold leading-6 text-blue-950 uppercase">
                        Blogs
                    </a>
                    {/* Contact page*/}
                    <a href={Contactus} className="text-sm font-bold leading-6 text-blue-950 uppercase">
                        Contact Us
                    </a>

                    {/*<a*/}
                    {/*    href={applicants}*/}
                    {/*    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"*/}
                    {/*>*/}
                    {/*    applicant*/}

                    {/*</a>*/}


                </Popover.Group>

            </nav>





            <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
                <div className="fixed inset-0 z-10" />
                <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                    <div className="flex items-center justify-between">
                        <a href="#" className="-m-1.5 p-1.5">
                            <span className="sr-only">Tee Gee Agency (Pvt) Ltd</span>
                            <img
                                className="h-8 w-auto"
                                src={logo}
                                alt="TeeGee Logo"
                            />
                        </a>
                        <button
                            type="button"
                            className="-m-2.5 rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(false)}
                        >
                            <span className="sr-only">Close menu</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <div className="mt-6 flow-root">
                        <div className="-my-6 divide-y divide-gray-500/10">
                            <div className="space-y-2 py-6">

                                <a
                                    href="#"
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Home
                                </a>
                                <a
                                    href={AboutPage}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    About
                                </a>
                                <a
                                    href={OurServices}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Services
                                </a>
                                <Disclosure as="div" className="-mx-3">
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                                Companies
                                                <ChevronDownIcon
                                                    className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                    aria-hidden="true"
                                                />
                                            </Disclosure.Button>
                                            <Disclosure.Panel className="mt-2 space-y-2">
                                                {[...companies, ...callsToAction].map((item) => (
                                                    <Disclosure.Button
                                                        key={item.name}
                                                        as="a"
                                                        href={item.href}
                                                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                    >
                                                        {item.name}
                                                    </Disclosure.Button>
                                                ))}
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                                <a
                                    href={JobListing}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    job Listings
                                </a>
                                <a
                                    href={OurWorkplace}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Our Workplace
                                </a>

                                <a
                                    href={Blog}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Blogs
                                </a>

                                <a
                                    href={Contactus}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    Contact Us
                                </a>



                            </div>
                            {/*<div className="py-6">*/}
                            {/*    <a*/}
                            {/*        href="#"*/}
                            {/*        className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"*/}
                            {/*    >*/}
                            {/*        Log in*/}
                            {/*    </a>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </Dialog.Panel>
            </Dialog>
        </header>
    )
}
