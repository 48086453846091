import React from 'react';
import Marquee from "react-fast-marquee";
import '../css/homepage/logo-slider/industries-logo.css';

import image1 from '../images/homepage/logo-slider/1.webp';
import image2 from '../images/homepage/logo-slider/2.webp';
import image3 from '../images/homepage/logo-slider/3.webp';
import image4 from '../images/homepage/logo-slider/4.webp';
import image5 from '../images/homepage/logo-slider/5.webp';
import image6 from '../images/homepage/logo-slider/6.webp';
import image7 from '../images/homepage/logo-slider/7.webp';
import image8 from '../images/homepage/logo-slider/8.webp';
import image9 from '../images/homepage/logo-slider/9.webp';
import image10 from '../images/homepage/logo-slider/10.webp';
import image11 from '../images/homepage/logo-slider/11.webp';
import image12 from '../images/homepage/logo-slider/12.webp';
import image13 from '../images/homepage/logo-slider/13.webp';
import image14 from '../images/homepage/logo-slider/14.webp';
import image15 from '../images/homepage/logo-slider/15.webp';
import image16 from '../images/homepage/logo-slider/16.webp';
import image17 from '../images/homepage/logo-slider/17.webp';
import image18 from '../images/homepage/logo-slider/18.webp';
import image19 from '../images/homepage/logo-slider/19.webp';
import image20 from '../images/homepage/logo-slider/20.webp';
import image21 from '../images/homepage/logo-slider/21.webp';
import image22 from '../images/homepage/logo-slider/22.webp';
import image23 from '../images/homepage/logo-slider/23.webp';
import image24 from '../images/homepage/logo-slider/24.webp';
import image25 from '../images/homepage/logo-slider/25.webp';
import image26 from '../images/homepage/logo-slider/26.webp';
import image27 from '../images/homepage/logo-slider/27.webp';
import image28 from '../images/homepage/logo-slider/28.webp';
import image29 from '../images/homepage/logo-slider/29.webp';
import image30 from '../images/homepage/logo-slider/30.webp';
import image31 from '../images/homepage/logo-slider/31.webp';
import image32 from '../images/homepage/logo-slider/32.webp';
import image33 from '../images/homepage/logo-slider/33.webp';
import image34 from '../images/homepage/logo-slider/34.webp';
import image35 from '../images/homepage/logo-slider/35.webp';



const HomepageIndustriesClients = () => {
    return (
        <div className="industry">

            <div className="content lg:flex md:flex">
                <div className="content-text lg:w-2/3 md:w-1/3 sm:w-1  text-gray-200 text-xl text-center align-middle py-3 bg-blue-800">
                    <h2 className="text-4xl font-bold leading-none tracking-tight text-gray-900 md:text-3xl lg:text-4xl dark:text-white">Industries & Clients</h2>
                    <span> “To diversify and create a global enterprise, enrich and enhance the lifestyle of young people locally and globally to shape up their destiny.”</span>
                </div>


                <div className="content-slider lg:w-2/3 md:w-2/3 sm:w-1 my-3 bg-white">
                    <Marquee>
                        <img className="me-2 logo-images" src={image1} alt="logo 1"/>
                        <img className="me-2 logo-images" src={image2} alt="logo 2"/>
                        <img className="me-2 logo-images" src={image3} alt="logo 3"/>
                        <img className="me-2 logo-images" src={image4} alt="logo 4"/>
                        <img className="me-2 logo-images" src={image5} alt="logo 5"/>
                        <img className="me-2 logo-images" src={image6} alt="logo 6"/>
                        <img className="me-2 logo-images" src={image7} alt="logo 7"/>
                        <img className="me-2 logo-images" src={image8} alt="logo 8"/>
                        <img className="me-2 logo-images" src={image9} alt="logo 9"/>
                        <img className="me-2 logo-images" src={image10} alt="logo 10"/>
                        <img className="me-2 logo-images" src={image11} alt="logo 11"/>
                        <img className="me-2 logo-images" src={image12} alt="logo 12"/>
                        <img className="me-2 logo-images" src={image13} alt="logo 13"/>
                        <img className="me-2 logo-images" src={image14} alt="logo 14"/>
                        <img className="me-2 logo-images" src={image15} alt="logo 15"/>
                        <img className="me-2 logo-images" src={image16} alt="logo 16"/>
                        <img className="me-2 logo-images" src={image17} alt="logo 17"/>
                        <img className="me-2 logo-images" src={image18} alt="logo 18"/>
                        <img className="me-2 logo-images" src={image19} alt="logo 19"/>
                        <img className="me-2 logo-images" src={image20} alt="logo 20"/>
                        <img className="me-2 logo-images" src={image21} alt="logo 21"/>
                        <img className="me-2 logo-images" src={image22} alt="logo 22"/>
                        <img className="me-2 logo-images" src={image23} alt="logo 23"/>
                        <img className="me-2 logo-images" src={image24} alt="logo 24"/>
                        <img className="me-2 logo-images" src={image25} alt="logo 25"/>
                        <img className="me-2 logo-images" src={image26} alt="logo 26"/>
                        <img className="me-2 logo-images" src={image27} alt="logo 27"/>
                        <img className="me-2 logo-images" src={image28} alt="logo 28"/>
                        <img className="me-2 logo-images" src={image29} alt="logo 29"/>
                        <img className="me-2 logo-images" src={image30} alt="logo 30"/>
                        <img className="me-2 logo-images" src={image31} alt="logo 31"/>
                        <img className="me-2 logo-images" src={image32} alt="logo 32"/>
                        <img className="me-2 logo-images" src={image33} alt="logo 33"/>
                        <img className="me-2 logo-images" src={image34} alt="logo 34"/>
                        <img className="me-2 logo-images" src={image35} alt="logo 35"/>

                    </Marquee>

                </div>

            </div>
        </div>


    );
};

export default HomepageIndustriesClients;