import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import axios from "axios";
import BackendUrl from "../Backend-url";
import bannerImage from "../images/job-listing/job-listing-wallpaper.webp";

const Projects = () => {
    const navigate = useNavigate();
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 9;

    useEffect(() => {
        const fetchProjectData = async () => {
            try {
                const response = await axios.get(`${BackendUrl}/gallery/partner-images/all`);
                setProjects(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchProjectData();
    }, []);

    // Logic to get current projects for the current page
    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

    const handleImageClick = (id) => {
        navigate(`/project/details/${id}`);
    };

    const handleMouseOver = (id) => {
        const nameText = document.getElementById(id);
        if (nameText) {
            nameText.style.transform = "translateY(0%)";
        }
    };

    const handleMouseOut = (id) => {
        const nameText = document.getElementById(id);
        if (nameText) {
            nameText.style.transform = "translateY(10%)";
        }
    };

    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '30vh',
        position: 'relative',
    };

    return (
        <>
            <Navbar />
            <section>
                <div style={containerStyle}>
                    <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center font-poppins">
                        <h2 className="text-slate-100 text-4xl font-bold uppercase">Our Projects</h2>
                    </div>
                </div>
            </section>
            <section>
                <div className=" max-w-7xl mx-auto text-center ">
                    <div className=" p-3">
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
                            {currentProjects.map(project => (
                                <div
                                    className="relative overflow-hidden    cursor-pointer"
                                    key={project.id}
                                    onMouseOver={() => handleMouseOver(project.id)}
                                    onMouseOut={() => handleMouseOut(project.id)}
                                >
                                    {/* Image */}
                                    <img
                                        onClick={() => handleImageClick(project.id)}
                                        src={`${BackendUrl}/${project.directoryPath.replace(/\\/g, '/')}/${project.coverImageWebp}`}
                                        alt={project.title}
                                        className="w-full h-auto rounded-lg "
                                    />
                                    {/* Overlay */}
                                    <div
                                        onClick={() => handleImageClick(project.id)}
                                        className="absolute inset-0 flex items-center justify-center "
                                    >
                                        {/* Text */}
                                        <div id={project.id} style={{ transform: 'translateY(450%)',transition: 'transform 0.5s', }}>
                                            <h3 className="text-white text-center text-lg font-semibold">{project.title}</h3>
                                            <p className="text-white text-center">{project.description}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {/* Pagination */}
                        <div>
                            {projects.length > projectsPerPage && (
                                <div className="flex justify-center mt-4">
                                    {Array.from({ length: Math.ceil(projects.length / projectsPerPage) }, (_, i) => (
                                        <button
                                            key={i}
                                            className={`mx-2 px-3 py-1 rounded-full ${currentPage === i + 1 ? 'bg-gray-800 text-white' : 'bg-gray-300 text-gray-800'}`}
                                            onClick={() => setCurrentPage(i + 1)}
                                        >
                                            {i + 1}
                                        </button>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Projects;
