import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Footer from "../component/footer";
import Navbar from "../component/navbar";
import Breadcrumb from '../component/breadcrumbs/breadcrumb';
import { FaSearch  } from "react-icons/fa";
import {useNavigate, useParams} from 'react-router-dom';

import BackendUrl from "../Backend-url";

import bannerImage from "../images/job-listing/job-listing-wallpaper.webp";
import { HomeIcon } from "@heroicons/react/24/solid";
import '../css/components/loader/loader.css';

const BlogDetails = () => {
    const navigate = useNavigate();
    const [blogDetails, setBlogDetails] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { id } = useParams();

    useEffect(() => {
        Promise.all([
            axios.get(`${BackendUrl}/blog/post/${id}`),
            axios.get(`${BackendUrl}/blog`)
        ])
            .then(([blogDetailsResponse, blogsResponse]) => {
                setBlogDetails(blogDetailsResponse.data);
                const sortedBlogs = blogsResponse.data.sort((a, b) => new Date(b.date_published) - new Date(a.date_published));
                setBlogs(sortedBlogs);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [id]); // Add id as a dependency here


    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '30vh',
        position: 'relative',
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'BLOG', url: '/blog' },
    ];

    const truncateWords = (text, maxWords) => {
        const words = text.split(' ');
        const truncatedWords = words.slice(0, maxWords);
        return truncatedWords.join(' ') + (words.length > maxWords ? '...' : ''); // Add ellipsis if truncated
    };

    const truncateCharacters = (text, maxCharacters) => {
        if (text.length <= maxCharacters) {
            return text;
        }

        const truncatedText = text.slice(0, maxCharacters);
        return truncatedText + '...';
    };

    const handleCardClick = (blogId) => {
        navigate(`/blog/details/${blogId}`); // Navigate to next component with image ID as parameter
    };


    return (
        <div className="bg-stone-200">
            <Navbar />
            <section>
                <div className="flex items-center justify-center" style={containerStyle}>
                    <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center">
                        <h2 className="text-slate-100 text-4xl font-bold uppercase">Tee Gee Blog</h2>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container mx-auto max-w-7xl">
                    <div className="mt-2">
                        <Breadcrumb items={breadcrumbItems} />
                    </div>
                </div>
            </section>
            <section>

                <div className="container mx-auto px-2 sm:px-5 py-2 lg:px-32 lg:pt-12 max-w-7xl flex flex-wrap">

                    {/* Blogs Section */}
                    <div className="w-full sm:w-1/2 lg:w-9/12 p-1 mb-4 sm:mb-0  h-full rounded-[36px] ">

                        {blogDetails.length === 0 ? (
                            <div className="loader"></div>
                        ) : (
                        <div className="bg-white p-2 h-full rounded-[36px] pl-2 py-3">
                            <div className="text-center uppercase font-extrabold"><h5 className="pl-2 text-xl ">{blogDetails.title}</h5></div>

                                    <div className=" p-2 flex items-center flex-col h-1/2 my-2 lg:flex-row rounded-[36px]" >
                                    <div className="w-md  ">
                                            <img className="rounded-[36px] p-2 w-screen" src={`${BackendUrl}/${blogDetails.image}`} alt={`Blog Image`} />
                                        </div>
                                        <div className="w-md p-2 text-justify ">

                                            <p className=" text-md font-thin">{blogDetails.content}</p>
                                        </div>
                                    </div>
                        </div>


                                )}

                    </div>

                    {/* Search and Recent News Section */}
                    <div className="w-full sm:w-1/2 lg:w-3/12  p-1 mb-4 sm:mb-0 h-full rounded-[36px]">
                        <div className="bg-white p-2 h-full rounded-[36px]">
                            {/*<div className=" p-2 h-2/5 rounded-[36px]">*/}
                            {/*    <div className="text-sm mb-2 px-1 py-2">Search</div>*/}
                            {/*    <div className="flex items-center">*/}
                            {/*        <input*/}
                            {/*            type="text"*/}
                            {/*            placeholder="Search"*/}
                            {/*            className="border p-2 mr-2 w-full rounded-[36px]"*/}
                            {/*        />*/}
                            {/*        <button className="bg-blue-500 text-white px-1 py-2 rounded-[36px]">Search</button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="= p-2 h-2/5 rounded-[36px]">
                                <div className="text-sm text-blue-500 font-extrabold mb-2 px-1 py-2">Search</div>
                                <div className="flex items-center">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="border p-2 mr-2 w-full rounded-[36px]"
                                    />
                                    <button className="bg-blue-500 text-white px-3 py-3 rounded-[36px]"><FaSearch/></button>
                                </div>
                            </div>


                            <div className=" p-2 h-3/5 mt-3 shadow-md rounded-[36px]">
                                <div className="text-sm mb-2 text-blue-500 font-extrabold px-1 py-2">Most Viewed </div>
                                {blogs.length === 0 ? (
                                    <div className="loader"></div>
                                ) : (
                                blogs
                                    .sort((a, b) => b.view_count - a.view_count) // Sort blogs by view count in descending order
                                    .slice(0, 5) // Take the top 5 blogs
                                    .map(blog => (
                                        <div className="  h-1/2 my-2 rounded-[36px] "
                                             key={blog.blog_id}
                                             onClick={() => handleCardClick(blog.blog_id)}  >
                                            <div className="p-3 h-1/2 flex rounded-[36px] ">
                                                <div className="w-1/4 ">
                                                    <img className="rounded-lg" src={`${BackendUrl}/${blog.image}`} alt={`Blog Image`} loading={"lazy"}/>
                                                </div>
                                                <div className="w-3/4 px-1 py-2 ">
                                                    <h5 className="pl-1 text-md font-medium">{blog.title}</h5>
                                                    <p className="pl-1 text-sm font-thin">{truncateCharacters(blog.content, 30)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                    )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default BlogDetails;
