import React, {useEffect} from 'react';
import Navbar from "../component/navbar";
import Footer from "../component/footer";
import Bottomto_up from "../component/bottomto_up";
import bannerImage from "../images/job-listing/job-listing-wallpaper.webp";
import AOS from "aos";


import bgimage  from "../images/pattern/bg-pattern-1.jpg";

const ContactUs = () => {

    useEffect(() => {
        AOS.init({
            duration: 1000, // Adjust the duration of the animation
            once: true, // Set true if you want the animation to occur only once
        });
    }, []);


    const containerStyle = {
        backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url(${bannerImage})`,
        backgroundSize: 'cover',
        width: '100%',
        height: '30vh',
        position: 'relative',
    };
    return (
        <div>
            <Navbar/>
            <Bottomto_up/>

            <section>
                <div style={containerStyle}>
                    <div className="sm:py-20 sm:pl-20 max-w-7xl mx-auto text-center font-poppins">
                        <h2 className="text-slate-100 text-4xl font-bold uppercase">Contact US</h2>
                    </div>
                </div>
            </section>





        <section style={{ backgroundImage: `url(${bgimage})`, backgroundSize: "400px" }} className="bg-repeat bg-center">
            <div style={{ backgroundColor: 'rgba(236, 236, 236, 0.80)' }}>
            <div className=" font-poppins">
                {/*<div style={{ backgroundImage: `url(${bgimage})` }} className="bg-cover bg-center">*/}
                <div className="container px-6 py-6 mx-auto max-w-screen-xl">


                    {/*<div className="text-center">*/}
                    {/*    <h3 className="text-gray-800 text-3xl font-semibold sm:text-4xl">*/}
                    {/*        Contact Us*/}
                    {/*    </h3>*/}
                    {/*</div>*/}

                    <div className="grid grid-cols-1 gap-12 mt-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                        <div className="p-4 rounded-lg  md:p-6 bg-[#252525]" data-aos="zoom-in">
                            <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>
                            </span>

                            <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Call us</h2>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Mon-Fri from 8am to 5pm.</p>
                            <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">+94 37 2 223 416 </p>
                            <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">+94 37 2 233 421 </p>
                            <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">+94 37 2 233 421 </p>
                        </div>

                        <div className="p-4 rounded-lg  md:p-6 bg-[#252525]" data-aos="zoom-in">
                            <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                                </svg>
                            </span>

                            <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Location</h2>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Visit our office HQ..</p>
                            <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">Weavers Shopping Complex, 18 2nd Floor, Mihindu Mawatha, Kurunegala</p>
                        </div>

                        <div className="p-4 rounded-lg  md:p-6 bg-[#252525] " data-aos="zoom-in">
                            <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                                </svg>
                            </span>

                            <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">Email</h2>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Speak to our friendly team.</p>
                            <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">teegeekg@gmail.com</p>
                        </div>



                        <div className="p-4 rounded-lg  md:p-6  bg-[#252525]"  data-aos="zoom-in">
                            <span className="inline-block p-3 text-blue-500 rounded-lg bg-blue-100/80 dark:bg-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 h-5">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z" />
                                </svg>
                            </span>

                            <h2 className="mt-4 text-base font-medium text-gray-800 dark:text-white">whats app</h2>
                            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">Visit our office HQ..</p>
                            <p className="mt-2 text-sm text-blue-500 dark:text-blue-400">+94 76 4 368 860</p>
                        </div>


                    </div>
                </div>
                {/*</div>*/}
            </div>


            {/* contact Form and map */}
            <div className=" " >
                <div className="container px-6 py-6 mx-auto max-w-screen-xl">

                    <div className="grid grid-cols-1 gap-12 mt-10 lg:grid-cols-2">
                        <div className="grid  " data-aos="zoom-in">
                            <iframe width="100%" height="100%" frameBorder="0" title="map" marginHeight="0" marginWidth="0" scrolling="no" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d247.23733604477565!2d80.36346!3d7.487612000000001!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae33a1e5c190413%3A0x6783c51a26619e30!2sTee%20Gee%20Agency%20(Pvt)%20Ltd.!5e0!3m2!1sen!2slk!4v1702100011276!5m2!1sen!2slk"></iframe>
                        </div>

                        <div className="p-4 py-6 rounded-lg bg-[#252525] md:p-8" data-aos="zoom-in">
                            <form>
                                <div className="-mx-2 md:items-center md:flex">
                                    <div className="flex-1 px-2">
                                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">First Name</label>
                                        <input type="text" placeholder="Your First name.. " className="block w-full px-3 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="flex-1 px-2  md:mt-0">
                                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Last Name</label>
                                        <input type="text" placeholder="Your Last name.." className="block w-full px-3  py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>
                                </div>

                                <div className="-mx-2 md:items-center md:flex mt-4">
                                    <div className="flex-1 px-2">
                                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Required Job</label>
                                        <input type="text" placeholder="Applying Position " className="block w-full px-3  py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="flex-1 px-2  md:mt-0">
                                        <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Contact number</label>
                                        <input type="number" placeholder="Enter your Contact Number" className="block w-full px-3  py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>
                                </div>

                                <div className="mt-4">
                                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Email address</label>
                                    <input type="email" placeholder="johndoe@example.com" className="block w-full px-3  py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                </div>

                                <div className="w-full mt-4">
                                    <label className="block mb-2 text-sm text-gray-600 dark:text-gray-200">Message</label>
                                    <textarea className="block w-full h-32 px-3  py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" placeholder="Message"></textarea>
                                </div>

                                <button className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                    Send message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </section>
            <Footer/>

        </div>
    );
};

export default ContactUs;