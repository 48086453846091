import React, { useState, useEffect } from 'react';
import '../css/homepage/carousel/HomepageCarousel.css'; // Import your CSS file for styling

import BgImage1 from '../images/homepage/carousel/1/BG.webp';
import CaptionImage1 from '../images/homepage/carousel/1/CAPTION SLIDE.webp';
import ManImage1 from '../images/homepage/carousel/1/MIDDLE MAN.webp';
import LogoImage1 from '../images/homepage/carousel/1/TEE GEE LOGO.webp';

import BgImage2 from '../images/homepage/carousel/2/BG.webp';

import BgImage3 from '../images/homepage/carousel/3/BG.webp';

const HomepageCarousel = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        // Trigger the animation when the component mounts
        setIsVisible(true);
    }, []);

    const images = [
        {
            bg: `${BgImage1}`,
            caption: `${CaptionImage1}`,
            logo: `${LogoImage1}`,
            man: `${ManImage1}`,
        },
        {
            bg: `${BgImage2}`,
        },
        {
            bg: `${BgImage3}`,
        },
    ];

    const containerStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
        opacity: isVisible ? 1 : 0,
        animation: 'fadeIn 1s ease-in-out',
    };

    const imageStyle = {
        position: 'absolute',
        top: 0,
    };

    const captionStyle = {
        ...imageStyle,
        left: isVisible ? 0 : '100%',
        animation: 'slideInFromRight 1s ease-in-out',
    };

    const logoStyle = {
        ...imageStyle,
        right: isVisible ? 15 : '100%',
        top: '10.33%',
        animation: 'slideInFromRight 1s ease-in-out',
    };

    const manStyle = {
        ...imageStyle,
        left: '0.33%',
        top: isVisible ? '15.33%' : '-100%',
        animation: 'slideInFromTop 1s ease-in-out',
        width: '66.66%',
    };

    const handleNext = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const handlePrev = () => {
        setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    return (
        <div>
            <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                <div className="carousel-inner">
                    {images.map((image, index) => (
                        <div key={index} className={`carousel-item ${index === currentImageIndex ? 'active' : ''}`}>
                            {image.bg && <img src={image.bg} className="d-block w-100" alt="Background" />}
                            {image.caption && (
                                <img src={image.caption} className="d-block w-100" alt="Caption" style={captionStyle} />
                            )}
                            {image.logo && (
                                <img src={image.logo} className="d-block w-100" alt="Logo" style={logoStyle} />
                            )}
                            {image.man && (
                                <img src={image.man} className="d-block w-100" alt="Man" style={manStyle} />
                            )}
                        </div>
                    ))}
                </div>
                <button className="carousel-control-prev" type="button" onClick={handlePrev}>
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" onClick={handleNext}>
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
};

export default HomepageCarousel;
