import Navbar from "../component/navbar";
import Footer from "../component/footer";
import { Link } from "react-router-dom";

import hospital from '../images/Companise/Hotel.webp';
import Driving from '../images/Companise/Driver.webp';
import FMCG from '../images/Companise/Super market.webp';
import Healthcare from '../images/Companise/Helth.care.webp';
import Automobile from '../images/Companise/auto mobile.webp';
import Catering from '../images/Companise/cooking.webp';
import Property from '../images/Companise/Property.webp';
import MEP from '../images/Companise/Construction.webp';

const handleMouseOver = (id) => {
    const nameText = document.getElementById(id);
    if (nameText) {
        nameText.style.transform = "translateY(0%)";
    }
};

const handleMouseOut = (id) => {
    const nameText = document.getElementById(id);
    if (nameText) {
        nameText.style.transform = "translateY(250%)";
    }
};

const ProjectLink = ({ to, imgSrc, id , name }) => {
    return (
        <Link to={to} className="relative block overflow-hidden font-poppins" onMouseOver={() => handleMouseOver(id)} onMouseOut={() => handleMouseOut(id)}>
            <div className="relative overflow-hidden group">
                <img className="h-auto max-w-full rounded-lg transform scale-100 group-hover:scale-110 transition-transform duration-500" src={imgSrc} alt="" />
                <div className="absolute inset-0 flex items-center justify-center">
                    <p
                        id={id}
                        className="text-white text-center font-bold text-2xl"
                        style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', transform: 'translateY(360%)', transition: 'transform 0.5s', }}
                    >
                        {name}
                    </p>
                </div>
            </div>
        </Link>
    );
};

const Partners = () => {
    return (
        <div>
            <Navbar />


            <div className="container mx-auto max-w-7xl my-10 font-poppins">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2">
                    <div className="grid gap-2">
                        <ProjectLink to="../partners/1" imgSrc={hospital} id="Hospitality Industry" name="Hospitality Industry" />
                        <ProjectLink to="../partners/5" imgSrc={Driving} id="Driving" name="Driving" />
                    </div>

                    <div className="grid gap-2">
                        <ProjectLink to="../partners/2" imgSrc={FMCG} id="FMCG & Supermarkets" name= "FMCG & Supermarkets" />
                        <ProjectLink to="../partners/6" imgSrc={Healthcare} id="Healthcare Industry"  name= "Healthcare Industry" />
                    </div>

                    <div className="grid gap-2">
                        <ProjectLink to="../partners/3" imgSrc={Automobile} id="Automobile Industry"   name= "Automobile Industry"/>
                        <ProjectLink to="../partners/7" imgSrc={Catering} id="Catering & Restaurant"   name= "Catering & Restaurant"/>
                    </div>

                    <div className="grid gap-2">
                        <ProjectLink to="../partners/4" imgSrc={Property} id="Property Management"  name= "Property Management" />
                        <ProjectLink to="../partners/8" imgSrc={MEP} id="MEP & Construction"  name= "MEP & Construction" />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Partners;
